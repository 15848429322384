import React from 'react';
import ReactDOM from 'react-dom/client';
import '../index.css';
import Header from '../components/Header';
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from '../components/Banner';
import PropertyItem from '../components/PropertyItem';
import { DropdownButton,Dropdown,Button } from 'react-bootstrap';
import Footer from '../components/Footer';
import {Helmet} from "react-helmet";
function PrivalcyNotices() {
  return (
    <>
     <Header />
    <Helmet>
      <meta name="description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"/>
      <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
      เพื่อให้ “ใครๆ ก็มีบ้านได้”" />
      <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
    </Helmet>
    <div className='container mt-5 policy'>
        <div className='row'>
            <div className='col-12 text-left'>
            <div className='text-center w-100'><img src='../../white.png' className='black-logo' /></div>
        <div className='text-center w-100 my-5'>
          <a href="/privacy-policy" className='btn-black width-260  mb-2'>นโยบายการคุ้มครองข้อมูลส่วนบุคคล<br/>(Privacy Policy)</a>
          <a href="#" className='btn-black width-260 btn-black-active  mb-2'>ประกาศการคุ้มครองข้อมูลส่วนบุคคล<br/>(Privacy Notice)</a>
          <a href="/cookies-policy" className='btn-black width-260  mb-2'>นโยบายคุกกี้<br/>(Privacy Cookies)</a>
        </div>
            <h3 className="text-center">ประกาศการคุ้มครองข้อมูลส่วนบุคคล ของ บริษัท เดอะ ไวท์สเปซ จำกัด</h3>
                        <h3 className="text-center">(Privacy Notice)</h3>
                        <h4>1. เราคือใคร</h4>
                          <ul>
                            <dl>บริษัท เดอะ ไวท์สเปซ จำกัด และบริษัทในกลุ่ม (“The Whitespace” หรือ “บริษัท”) ตระหนักถึงความสำคัญของการปกป้องความเป็นส่วนตัวของข้อมูล
                                บริษัทเคารพความเป็นส่วนตัวและการรักษาความลับข้อมูลส่วนบุคคลของเจ้าของข้อมูลบุคคล เรามุ่งมั่นที่จะดำเนินการตามนโยบายแนวปฏิบัติและกระบวนการเพื่อ
                                ปกป้องการเก็บรวบรวม การใช้และการเปิดเผยข้อมูลส่วนบุคคลที่ท่านให้เราโดยเป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 
                                “พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล”) บริษัทจึงได้จัดทำประกาศการคุ้มครองข้อมูลส่วนบุคคล (Privacy Notice) ฉบับนี้
                                เพื่ออธิบายให้ท่านทราบถึงแนวทางปฏิบัติของบริษัทในส่วนที่เกี่ยวข้องกับข้อมูลส่วนบุคคลและเหตุผลในการเก็บรวบรวม การใช้
                                การเปิดเผยข้อมูลส่วนบุคคลของท่านและสิทธิของท่านในฐานะเจ้าของข้อมูลส่วนบุคคล โดยประกาศฉบับนี้เป็นส่วนหนึ่งของนโยบายคุ้มครองข้อมูลส่วนบุคคล
                                ของบริษัท</dl>
                          </ul>
                        <h4>2. ประกาศนี้ใช้กับบุคคลดังต่อไปนี้</h4>
                          <ul>
                            <li>ลูกค้าหรือผู้ใช้บริการของบริษัทที่เป็นบุคคลธรรมดา</li>
                            <li>คู่ค้า ผู้ประกอบการ พันธมิตรทางธุรกิจ ผู้ให้บริการ คู่สัญญาของบริษัท (รวมเรียกว่า “คู่ค้า”) ที่เป็นบุคคลธรรมดา</li>
                            <li>บุคคลธรรมดาที่มีความเกี่ยวข้องหรือมีความสัมพันธ์กับคู่ค้าของบริษัทกรณีที่คู่ค้าดังกล่าวเป็นนิติบุคคล เช่น ผู้ถือหุ้น 
                                กรรมการ ผู้รับมอบอำนาจกระทำการแทน ผู้ประสานงาน ผู้ติดต่อ พนักงาน หรือบุคคลใดที่ได้รับมอบหมาย แล้วแต่กรณี</li>
                            <li>บุคคลธรรมดาที่ไม่ใช่ลูกค้าหรือผู้ใช้บริการสินค้าหรือบริการของบริษัท แต่บริษัทมีความจำเป็นต้องเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคล</li>
                            <li>บุคคลธรรมดาที่ได้ให้ข้อมูลส่วนบุคคลกับบริษัทหรือที่บริษัทได้รับข้อมูลส่วนบุคคลมาทั้งทางตรงและทางอ้อมไม่ว่าผ่านช่องทางใด</li>
                          </ul>
                        <h4>3. ข้อมูลส่วนบุคคล คืออะไร</h4>
                          <ul>
                            <dl><strong>ข้อมูลส่วนบุคคล</strong>
                            หมายถึง ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อมแต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ</dl>
                            <dl><strong>ข้อมูลส่วนบุคคลอ่อนไหว</strong>
                            หมายถึง ข้อมูลส่วนบุคคลที่เกี่ยวกับศาสนา ประวัติอาชญากรรม ข้อมูลสุขภาพ (เช่น หมู่เลือด, ประวัติสุขภาพ เป็นต้น), ข้อมูลชีวภาพ (เช่น ลายนิ้วมือ, ข้อมูลภาพจำลองใบหน้า เป็นต้น)</dl>
                          </ul>
                        <h4>4. ข้อมูลส่วนบุคคลที่บริษัทเก็บรวบรวม</h4>
                        <h5><strong>บริษัทจะเก็บรวบรวมข้อมูลส่วนบุคคลอันเกี่ยวกับการใช้บริการ ธุรการ หรือธุรกรรมต่าง ๆ ในปัจจุบันดังต่อไปนี้</strong></h5>
                        <table   className="table table-bordered w-100">
                              <tbody><tr>
                                <th className="text-center w-30" >ประเภท</th>
                                <th className="text-center">ตัวอย่างข้อมูลส่วนบุคคล</th>
                              </tr>
                              <tr>
                                <td>ข้อมูลที่บ่งชี้ตัวตน</td>
                                <td>ข้อมูลที่บ่งชี้ตัวตน	ชื่อ-นามสกุล อายุ วัน/เดือน/ปีเกิด เพศ ส่วนสูงสัญชาติ
                                    รูปถ่ายหมายเลขบัตรประจำตัวประชาชน หมายเลขหนังสือเดินทาง รหัสประจำตัว การศึกษา ประวัติการทำงาน ลายมือชื่อ</td>
                              </tr>
                              <tr>
                                <td>ข้อมูลติดต่อ</td>
                                <td>ข้อมูลติดต่อ ที่อยู่ ที่ทำงาน หมายเลขโทรศัพท์ Email ช่องทางติดต่อผ่านทางสื่อสังคมออนไลน์ต่างๆ</td>
                              </tr>
                              <tr>
                                <td>ข้อมูลการใช้บริการ</td>
                                <td>ข้อมูลอุปกรณ์ที่ท่านใช้สำหรับการเข้าใช้งานเว็บไซต์ ข้อมูลจราจรทางคอมพิวเตอร์ (Log) ข้อมูลการติดต่อและสื่อสารระหว่างท่านและผู้ใช้งานรายอื่น
                                    และข้อมูลจากการบันทึกการใช้งาน เช่น ตัวระบุอุปกรณ์ หมายเลข IP ของคอมพิวเตอร์ รหัสประจำตัวอุปกรณ์ ประเภทอุปกรณ์ 
                                    ข้อมูลเครือข่ายมือถือ ข้อมูลการเชื่อมต่อ ข้อมูลตำแหน่งที่ตั้งทางภูมิศาสตร์ ประเภทของเบราว์เซอร์ (Browser) ข้อมูลบันทึกการเข้าออกเว็บไซต์
                                     ข้อมูลเว็บไซต์ที่ผู้ใช้งานเข้าถึงก่อนและหลัง (Referring Website) ข้อมูลบันทึกประวัติการใช้เว็บไซต์ ข้อมูลบันทึกการเข้าสู่ระบบ 
                                     (Login Log) พฤติกรรมการใช้งาน (Customer Behavior) สถิติการเข้าเว็บไซต์ เวลาที่เยี่ยมชมเว็บไซต์ (Access Time) ข้อมูลที่ท่านค้นหา
                                      การใช้ฟังก์ชันต่าง ๆ ในเว็บไซต์ และข้อมูลที่บริษัทได้เก็บรวบรวมผ่านคุกกี้หรือเทคโนโลยีอื่นที่คล้ายกัน</td>
                              </tr>
                              <tr>
                                <td>ข้อมูลเกี่ยวกับบุคคลากร</td>
                                <td>ข้อมูลเกี่ยวกับบุคคลากร	ใบอนุญาตและประกาศนียบัตรต่าง ๆ ข้อมูลเกี่ยวกับสวัสดิการและผลประโยชน์ต่าง ๆ ข้อมูลการปฏิบัติงานและข้อมูลทางวินัย
                                    ข้อมูลบุคคลที่สาม (ครอบครัว ผู้ค้ำประกัน บุคคลอ้างอิง บุคคลผู้ติดต่อในกรณีฉุกเฉิน) ข้อมูลประกันสังคม ข้อมูลบัญชีธนาคาร</td>
                              </tr>
                              <tr>
                                <td>ข้อมูลส่วนบุคคลอ่อนไหว</td>
                                <td>ศาสนา ประวัติอาชญากรรม ข้อมูลสุขภาพ (เช่น หมู่เลือด ประวัติสุขภาพ เป็นต้น)</td>
                              </tr>
                              <tr>
                                <td>ข้อมูลอื่นๆ</td>
                                <td>สถานะสมรส ความสนใจ ความเห็น ข้อมูลการเดินทาง การขอใช้สิทธิตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล</td>
                              </tr>
                            </tbody></table> 
                        <h4>5. แหล่งที่มาของข้อมูลส่วนบุคคล</h4>
                        <h5>บริษัทอาจได้รับข้อมูลส่วนบุคคลของท่านจากช่องทางดังนี้</h5>
                          <ol>
                            <dl>
                            5.1 บริษัทได้รับข้อมูลส่วนบุคคลจากท่านโดยตรง โดยบริษัทจะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านจากขั้นตอนการให้บริการต่าง ๆ รวมถึง
                              <ul>
                                <li>ขั้นตอนการสมัครใช้บริการ หรือขั้นตอนการยื่นคำร้องขอใช้สิทธิต่าง ๆ กับบริษัท</li>
                                <li>จากความสมัครใจของท่าน ในการทำแบบสอบถาม (Survey) หรือการโต้ตอบทาง Email หรือช่องทางการสื่อสารอื่น ๆ ระหว่างบริษัทและท่าน</li>
                                <li>จากการที่ท่านใช้บริการของบริษัท</li>
                                <li>จากข้อมูลการใช้เว็บไซต์ของบริษัทผ่าน Browser’s cookies ของท่าน</li>
                                <li>จากการเยี่ยมชมและให้ข้อมูลผ่านเว็บไซต์ และแพลตฟอร์มโซเชียลมีเดียของบริษัท</li>
                                <li>จากการที่ท่านเข้าร่วมกิจกรรมทางการตลาด เยี่ยมชมบูธ หรือเข้าร่วมกิจกรรมใด ๆ ไม่ว่าจะเป็นการประชุมเชิงปฏิบัติการ หรือนิทรรศการ 
                                      หรือเข้าร่วมในแบบสอบถาม หรือการจับรางวัล</li>
                                <li>เมื่อท่านสมัครงาน/ฝึกงาน กับบริษัท</li>
                              </ul>
                            </dl>
                            <dl>
                              5.2 บริษัทอาจได้รับข้อมูลส่วนบุคคลของท่านมาจากบุคคลที่สามซึ่งมีสิทธิเปิดเผยข้อมูลส่วนบุคคลของท่านโดยชอบด้วยกฎหมาย 
                              และผู้รับจ้างช่วงที่บริษัทใช้เพื่อช่วยให้บริษัทสามารถเสนอบริการและให้บริการแก่ท่าน โดยได้รับข้อมูลผ่านทาง Email ได้รับแจ้งทางโทรศัพท์
                               หรือได้รับเป็นเอกสารซึ่งผู้ที่เปิดเผยมีสิทธิให้ข้อมูลส่วนบุคคลได้โดยชอบด้วยกฎหมาย หรือได้รับข้อมูลจากขั้นตอนการให้บริการต่าง ๆ 
                               ในลักษณะเดียวกันที่บริษัทเก็บรวบรวมเองตามที่ระบุไว้ข้างต้น
                            </dl>
                          </ol>
                        <h4>6. วัตถุประสงค์ในการเก็บรวบรวมหรือใช้ข้อมูลส่วนบุคคล</h4>
                        <h5>บริษัทจะดำเนินการเก็บรวมรวมหรือใช้ข้อมูลส่วนบุคคลของเจ้าของข้อมูลเพื่อประโยชน์ในการดำเนินงานของบริษัทดังนี้</h5>
                          <ul className="ul">
                          <li>เพื่อปรับปรุงคุณภาพในการปฏิบัติงานและการให้บริการให้มีประสิทธิภาพมากยิ่งขึ้น</li>
                          <li>เพื่อให้บริการหรือดำเนินการตามสัญญาซึ่งท่านเป็นคู่สัญญา</li>
                          <li>เพื่ออำนวยความสะดวกในการแจ้งหรือการติดตามการชำระค่าสินค้าและบริการหรือค่าธรรมเนียมที่เกี่ยวข้อง การจัดทำใบเสร็จรับเงินและใบกำกับภาษี</li>
                          <li>เพื่อการสมัคร ใช้บริการ สั่งซื้อสินค้าหรือบริการต่างๆ ของบริษัทและบริษัทในกลุ่ม รวมถึงการพิสูจน์และยืนยันตัวตนสำหรับการเข้าใช้บริการ</li>
                          <li>เพื่อวิเคราะห์ข้อมูลการให้บริการ แผนการตลาด หรือกิจกรรมทางการตลาดของบริษัทและบริษัทในกลุ่ม </li>
                          <li>เพื่อสอบถามและประเมิน ความเข้าใจ ความต้องการ และความพึงพอใจในการใช้บริการ</li>
                          <li>เพื่อแจ้งและนำเสนอ สิทธิประโยชน์ ข้อมูล ข่าวสาร รายการส่งเสริมการขาย และข้อเสนอต่างๆ ของบริษัทและบริษัทในกลุ่มรวมถึงพันธมิตร
                                ของบริษัทผ่านทางช่องทางต่างๆ</li>
                          <li>เพื่อการปฏิบัติให้เป็นไปตามกฎหมายหรือกฎระเบียบที่เกี่ยวข้องต่อการดำเนินงานของบริษัทหรือเพื่อวัตถุประสงค์อื่นใดตามที่กฎหมายที่เกี่ยวข้องกำหนด</li>
                          </ul>
                         <h5>โดยบริษัทจะจัดเก็บและใช้ข้อมูลดังกล่าวตามระยะเวลาเท่าที่จำเป็นตามวัตถุประสงค์ที่ได้แจ้งเจ้าของข้อมูลหรือตามที่กฎหมายกำหนดไว้เท่านั้น ทั้งนี้ 
                             บริษัทจะไม่กระทำการใดๆ แตกต่างจากที่ระบุในวัตถุประสงค์ของการเก็บรวบรวม เว้นแต่ ได้แจ้งวัตถุประสงค์ใหม่ให้แก่เจ้าของข้อมูลทราบและได้รับความยินยอม
                             จากเจ้าของข้อมูล หรือเป็นการปฏิบัติตามกฎหมายที่เกี่ยวข้อง </h5>
                         <h4>7. การเปิดเผยข้อมูลส่วนบุคคลให้บุคคลอื่น</h4>
                          <ol>
                          <dl>7.1  เมื่อได้รับข้อมูลส่วนบุคคลจากแหล่งที่มาของข้อมูลส่วนบุคคลแล้ว บริษัทจะดำเนินการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านตาม
                                   วัตถุประสงค์ที่ได้แจ้งไว้ และจะประมวลผลข้อมูลส่วนบุคคลของท่านเท่าที่กฎหมายอนุญาตให้กระทำได้เท่านั้น</dl>
                          <dl>7.2  บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลและข้อมูลใดๆ อันเกี่ยวกับการใช้บริการของท่านหรือข้อมูลอื่นใดที่บริษัทเก็บรวบรวมไว้ข้างต้นให้แก่ 
                                   บริษัทในกลุ่ม พันธมิตรทางธุรกิจของบริษัทหรือหน่วยงานราชการตามกฎหมาย รวมถึงนิติบุคคลหรือบุคคลอื่นใดที่บริษัทและ/หรือท่านเป็นคู่สัญญา
                                   หรือมีนิติสัมพันธ์ตามกฎหมาย ทั้งในและต่างประเทศ นอกจากนี้ บริษัทอาจมีความจำเป็นในการโอนข้อมูลส่วนบุคคลของท่านไปยังบริษัทอื่นหรือกลุ่ม
                                   พันธมิตรที่บริษัทเป็นคู่สัญญาหรือมีนิติสัมพันธ์ตามกฎหมาย รวมทั้งแพลตฟอร์มของคลาวด์ (Cloud) ในต่างประเทศ เพื่อเป็นประโยชน์ต่อการดำเนิน
                                   งานของบริษัทและการให้บริการแก่เจ้าของข้อมูล โดยในการเปิดเผยข้อมูลส่วนบุคคลให้แก่บุคคลดังกล่าว บริษัทจะดำเนินการให้บุคคลเหล่านั้นเก็บรักษา
                                   ข้อมูลส่วนบุคคลไว้เป็นความลับและไม่นำไปใช้เพื่อวัตถุประสงค์อื่นนอกเหนือจากขอบเขตที่บริษัทได้กำหนดไว้</dl>
                          <dl>7.3  บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลภายใต้หลักเกณฑ์ที่กฎหมายกำหนด เช่น การเปิดเผยข้อมูลส่วนบุคคลต่อหน่วยงานราชการ 
                                   หน่วยงานภาครัฐ หน่วยงานกำกับดูแล รวมถึงในกรณีที่มีการร้องขอให้เปิดเผยข้อมูลโดยอาศัยอำนาจตามกฎหมาย</dl>
                          </ol> 
                         <h5>ทั้งนี้ หากมีการเปลี่ยนแปลงในธุรกิจของบริษัทในส่วนที่มีการจำหน่ายไป ได้มา หรือควบรวมกับบริษัทอื่น (แล้วแต่กรณี) อาจมีการใช้ข้อมูลของท่านในลักษณะ
                             เดียวกับที่อธิบายไว้ในประกาศการคุ้มครองข้อมูลส่วนบุคคลนี้ </h5>
                        <h4>8. การเก็บรักษาและระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล</h4>
                          <ol>
                            <dl>8.1 บริษัทจะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านในระยะเวลาเท่าที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ของบริการแต่ละประเภท ตามระยะเวลาที่มีสัญญา
                                หรือนิติสัมพันธ์ตามกฎหมายที่บังคับใช้ระหว่างท่านและบริษัทเว้นแต่เพื่อการปฏิบัติตามกฎหมายและกฎระเบียบที่ใช้บังคับกับบริษัทหรือเพื่อให้เป็นไปตาม
                                ข้อกำหนดในการปฏิบัติงานภายในของบริษัทหรือเพื่อดำเนินการตามข้อเรียกร้องทางกฎหมายหรือกรณีที่หน่วยงานกำกับดูแลร้องขอ ซึ่งข้อมูลจะถูกจัดเก็บรักษา
                                ไว้ต่อไปตามระยะเวลาที่เหมาะสมและจำเป็นสำหรับข้อมูลส่วนบุคคลแต่ละประเภท</dl>
                            <dl>8.2 บริษัทจะดำเนินการลบหรือทำลายข้อมูลส่วนบุคคลหรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลได้ 
                                เมื่อพ้นระยะเวลาจัดเก็บหรือเมื่อบริษัทไม่มีสิทธิหรือไม่สามารถอ้างฐานในการประมวลผลข้อมูลส่วนบุคคลของท่านแล้ว</dl>
                          </ol>
                         <h4>9. สิทธิของเจ้าของข้อมูลส่วนบุคคล</h4>
                         <h5>ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 ท่านในฐานะเจ้าของข้อมูลส่วนบุคคลมีสิทธิเกี่ยวกับข้อมูลส่วนบุคคล ดังนี้</h5>
                          <ol>
                              <dl>9.1 สิทธิในการถอนความยินยอม (Right to withdraw consent)
                                  <ul>
                                    <li>เมื่อท่านให้ความยินยอมกับบริษัทเพื่อวัตถุประสงค์อย่างหนึ่งอย่างใดโดยเฉพาะแล้ว ท่านมีสิทธิถอนความยินยอมเมื่อใดก็ได้ เว้นแต่เป็นกรณีที่มีข้อจำกัด
                                        สิทธิตามกฎหมาย หรือเกี่ยวข้องกับสัญญาที่ให้ประโยชน์แก่ท่าน</li>
                                    <li>ท่านมีสิทธิในการถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมกับบริษัทได้ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านอยู่
                                        กับบริษัททั้งนี้ การเพิกการถอนความยินยอมของท่านจะไม่ส่งผลกระทบต่อการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านที่ได้ให้ความยินยอม
                                        ไว้แล้วก่อนการถอนความยินยอมดังกล่าว</li>
                                    <li>หากการถอนความยินยอมของท่านทำให้บริษัทไม่สามารถให้บริการบางอย่างให้แก่ท่านได้ บริษัทจะแจ้งให้ท่านทราบถึงผลกระทบจากการถอนความยินยอม</li>
                                  </ul>
                              </dl>
                              <dl>9.2 สิทธิในการขอเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคล (Right to access)
                                <ul>
                                  <li>ท่านมีสิทธิในการขอเข้าถึงข้อมูลส่วนบุคคลของท่าน และขอให้บริษัททำสำเนาข้อมูลส่วนบุคคลดังกล่าวให้แก่ท่าน</li>
                                </ul>
                              </dl>
                              <dl>9.3 สิทธิในการขอให้โอนข้อมูลส่วนบุคคล (Right to data portability)
                                <ul>
                                  <li>ท่านมีสิทธิในการขอให้บริษัทโอนข้อมูลส่วนบุคคลของท่านที่ท่านให้ไว้กับบริษัทไปยังผู้ควบคุมข้อมูลส่วนบุคคลรายอื่นหรือตัวท่านเองด้วย
                                    เหตุบางประการได้ ทั้งนี้ สิทธิในการขอให้โอนนี้ใช้เฉพาะกรณีของข้อมูลส่วนบุคคลที่ท่านส่งมอบให้แก่บริษัทและการเก็บรวบรวม ใช้ และ/หรือ 
                                    เปิดเผยข้อมูลส่วนบุคคลดังกล่าวได้กระทำโดยอาศัยความยินยอมของท่าน หรือในกรณีที่ข้อมูลส่วนบุคคลดังกล่าวจำเป็นต้องได้รับการเก็บรวบรวม 
                                    ใช้ และ/หรือ เปิดเผยเพื่อให้สามารถปฏิบัติตามภาระข้อผูกพันภายใต้สัญญาได้เท่านั้น</li>
                                </ul>
                              </dl>
                              <dl>9.4 สิทธิในการคัดค้านการเก็บรวบรวม ใช้ เปิดเผย และประมวลผลข้อมูลส่วนบุคคล (Right to object)
                                <ul>
                                  <li>ท่านมีสิทธิในการคัดค้านเก็บรวบรวม ใช้ เปิดเผย และประมวลผลข้อมูลส่วนบุคคลของท่านด้วยเหตุบางประการได้ ซึ่งการใช้สิทธิคัดค้านของท่านอาจทำให้บริษัท
                                    ไม่สามารถให้บริการแก่ท่านได้</li>
                                </ul>
                              </dl>
                              <dl>9.5 สิทธิในการขอให้ลบข้อมูลส่วนบุคคล (Right to erasure)
                                <ul>
                                  <li>ท่านมีสิทธิขอให้บริษัทลบหรือทำลายข้อมูลของท่าน หรือทำให้ข้อมูลของท่านเป็นข้อมูลที่ไม่สามารถระบุตัวตนของท่านได้ เว้นแต่กรณีที่บริษัท
                                    มีความจำเป็นต้องปฏิบัติตามสัญญาสำหรับการให้บริการหรือมีเหตุอันชอบด้วยกฎหมายในการปฏิเสธคำขอของท่าน</li>
                                </ul>
                              </dl>
                              <dl>9.6 สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคล (Right to restriction of processing)
                                <ul>
                                  <li>ท่านมีสิทธิขอให้บริษัทระงับการใช้ข้อมูลส่วนบุคคลชั่วคราว ในกรณีที่บริษัทอยู่ระหว่างการตรวจสอบตามคำร้องขอใช้สิทธิขอแก้ไขข้อมูลส่วนบุคคล
                                    หรือขอคัดค้านของท่าน หรือกรณีอื่นใดที่บริษัทหมดความจำเป็นและต้องลบหรือทำลายข้อมูลส่วนบุคคลของท่านตามกฎหมายที่เกี่ยวข้องแต่ท่านขอ
                                    ใช้สิทธิให้บริษัทระงับการใช้แทน</li>
                                </ul>
                              </dl>
                              <dl>9.7 สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง (Right to rectification)
                                <ul>
                                  <li>ท่านมีสิทธิในการขอให้บริษัทแก้ไขข้อมูลส่วนบุคคลของท่านที่ไม่ถูกต้อง ไม่เป็นปัจจุบันหรือไม่สมบูรณ์ โดยบริษัทจะดำเนินการตรวจสอบและแก้ไขให้ถูกต้อง</li>
                                  <li>ในกรณีที่ท่านขอแก้ไขข้อมูลส่วนบุคคลบางอย่างที่บริษัทพิจารณาแล้วว่ามีความจำเป็นต้องยืนยันตัวตนท่านเพิ่มเติม บริษัทจะขอเอกสารและ/หรือข้อมูลเพิ่มเติม</li>
                                </ul>
                              </dl>
                              <dl>9.8 สิทธิในการร้องเรียน (Right to make a complaint)
                                <ul>
                                  <li>ท่านมีสิทธิในการร้องเรียนเกี่ยวกับข้อมูลส่วนบุคคลได้ โดยท่านสามารถแจ้งมาที่ช่องทางการติดต่อของบริษัทเพื่อได้รับการตรวจสอบชี้แจง
                                    หรือแก้ไขข้อกังวลต่างๆ หรือร้องเรียนไปยังหน่วยงานที่มีอำนาจในการกำกับดูแลตาม พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล</li>
                                  <li>ท่านสามารถแจ้งปัญหาเกี่ยวกับข้อมูลส่วนบุคคล อันรวมถึงแต่ไม่จำกัดเพียงการขอให้ผู้ควบคุมข้อมูลแก้ไขปรับปรุงข้อมูลให้เป็นปัจจุบัน 
                                    และ/หรือการคัดค้านการเก็บรวบรวมข้อมูล หรือระงับการใช้ข้อมูลได้ตามช่องทางการติดต่อที่บริษัทกำหนดไว้ </li>
                                </ul>
                              </dl>
                          </ol>
                        <h5>ทั้งนี้ ท่านสามารถขอใช้สิทธิของท่านภายใต้ข้อกำหนดของกฎหมาย โดยสามารถติดต่อเจ้าหน้าที่ของบริษัทหรือขอใช้สิทธิด้วยตัวท่านเอง 
                            ตามช่องทางที่บริษัทกำหนดไว้ โดยบริษัทอาจขอให้ท่านแสดงหลักฐานเพื่อพิสูจน์ว่าท่านเป็นเจ้าของข้อมูลจริง โดยที่ท่านไม่ต้องเสียค่าธรรมเนียมในการใช้สิทธิของ
                            ท่าน อย่างไรก็ดี บริษัทขอสงวนสิทธิ์ในการเรียกเก็บค่าธรรมเนียมตามแต่กรณี หากพบว่าคำขอของท่านไม่มีมูล ซ้ำซาก หรือมีจำนวนมากเกินจำเป็น 
                            และขอสงวนสิทธิ์ในการปฏิเสธคำร้องขอที่เป็นการใช้สิทธิโดยไม่สุจริต คำร้องขอที่ไม่สมเหตุสมผล คำร้องขอที่กฎหมายไม่ได้บัญญัติไว้ และ/หรือคำร้องขอที่
                            ไม่สามารถปฏิบัติได้จริง ตามหลักเกณฑ์ที่กฎหมายกำหนด</h5>
                        <h4>10. ในกรณีที่ท่านปฏิเสธไม่ให้ใช้ข้อมูลส่วนบุคคล</h4>
                          <ol>
                            <dl>บริษัทมีความจำเป็นต้องรวบรวมข้อมูลส่วนบุคคลตามกฎหมายหรือเพื่อการเข้าทำสัญญาระหว่างท่านกับบริษัทหากท่านเลือกที่จะไม่ให้ข้อมูลส่วนบุคคล 
                                บริษัทอาจไม่สามารถให้บริการแก่ท่านได้นอกเหนือจากกรณีที่บริษัทใช้ข้อมูลส่วนบุคคลเพื่อการปฏิบัติตามสัญญาและการปฏิบัติตามกฎหมาย 
                                ในกรณีที่ท่านไม่ยินยอมให้ใช้ข้อมูลส่วนบุคคล ท่านยังคงสามารถใช้บริการของบริษัทได้ โดยท่านอาจได้รับความสะดวกจากการใช้บริการน้อยลง 
                                เนื่องจากบริษัทไม่ได้รับความยินยอมให้ใช้ข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์สำหรับการให้บริการอย่างเต็มประสิทธิภาพ</dl>
                          </ol>
                        <h4>11. นโยบายคุกกี้ (Cookies)</h4>
                        <ol>
                          <dl>เว็บไซต์ของบริษัทมีการใช้งานคุกกี้หรือเทคโนโลยีอื่นใดที่ใกล้เคียงกันเพื่อช่วยแยกแยะรูปแบบการใช้งานเว็บไซต์ของผู้ใช้งาน 
                              ซึ่งจะช่วยให้ผู้ใช้งานได้รับประสบการณ์ที่ดีจากการใช้บริการ และยังเป็นส่วนช่วยให้บริษัทสามารถพัฒนาคุณภาพของการให้บริการให้ตอบสนองต่อความ
                              ต้องการของผู้ใช้งานมากยิ่งขึ้น ซึ่งท่านสามารถศึกษาข้อมูลเพิ่มเติมได้จากนโยบายการใช้คุกกี้ของบริษัท 
                              <a href="/cookies-policy"><h5>นโยบายคุกกี้ (Privacy Cookies)</h5></a></dl>    
                        </ol>
                        <h4>12. การเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลตามวัตถุประสงค์เดิม</h4>
                        <ol>
                          <dl>บริษัทจะประมวลผลข้อมูลส่วนบุคคลตามกฎหมายที่ใช้บังคับอยู่ในปัจจุบัน ทั้งนี้ บริษัทมีสิทธิเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลที่บริษัทเก็บรวบรวม
                              ไว้ก่อนวันที่ พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคลมีผลใช้บังคับต่อไปได้ตามวัตถุประสงค์เดิม โดยหากท่านไม่ประสงค์ที่จะให้บริษัทเก็บรวมรวมและใช้ข้อมูลส่วน
                              บุคคลดังกล่าวต่อไปภายหลังพ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล ใช้บังคับแล้ว ท่านสามารถแจ้งยกเลิกความยินยอมได้ตามช่องทางติดต่อที่บริษัทกำหนดไว้</dl>
                        </ol>
                        <h4>13. นโยบายคุ้มครองข้อมูลส่วนบุคคลของเว็บไซต์อื่น</h4>
                        <ol>
                          <dl>ประกาศการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ใช้เฉพาะสำหรับการให้บริการของบริษัทและบริษัทในกลุ่ม และการใช้งานเว็บไซต์ของบริษัทและบริษัทในกลุ่มเท่านั้น
                              หากท่านได้เชื่อมต่อไปยังเว็บไซต์อื่น แม้จะผ่านช่องทางในเว็บไซต์ของบริษัทก็ตาม ท่านจะต้องศึกษาและปฏิบัติตามนโยบายคุ้มครองข้อมูลส่วนบุคคลที่ปรากฏ
                              ในเว็บไซต์นั้น ๆ แยกต่างหากจากของบริษัท</dl>
                        </ol>
                        <h4>14. การเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล</h4>
                        <ol>
                          <dl>บริษัทจะทำการพิจารณาเปลี่ยนแปลง แก้ไข เพิ่มเติมประกาศการคุ้มครองข้อมูลส่วนบุคคลเป็นครั้งคราวเพื่อให้สอดคล้องกับแนวปฏิบัติ และกฎหมาย 
                              ข้อบังคับที่เกี่ยวข้อง ทั้งนี้ บริษัทขอสงวนสิทธิในการเปลี่ยนแปลงนโยบายดังกล่าวในเว็บไซต์โดยไม่ต้องแจ้งให้ท่านทราบล่วงหน้า</dl>
                        </ol>
                        <h4>15. เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</h4>
                        <ol>
                          <dl>บริษัทได้ปฏิบัติตาม พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล โดยแต่งตั้งเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer : DPO) 
                              เพื่อตรวจสอบการดำเนินการของบริษัทที่เกี่ยวกับการประมวลผลข้อมูลส่วนบุคคล รวมถึงกฎหมายที่เกี่ยวข้องกับการคุ้มครองข้อมูลส่วนบุคคล และเพื่อให้
                              การดำเนินงานตามแนวนโยบายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลให้เป็นไปด้วยความเรียบร้อย</dl>
                        </ol>
                        <h4>16. ช่องทางติดต่อกับบริษัท</h4>
                      <ol>
                        <dl><p><strong>ฝ่ายกำกับดูแลการปฎิบัติตามกฎระเบียบ</strong></p>
                        <p><strong>บริษัท เดอะ ไวท์สเปซ จำกัด </strong></p>
                        <p>87 โครงการเดอะแจส รามอินทรา ชั้น 3 ห้อง A302 ถนนลาดปลาเค้า แขวงอนุสาวรีย์ เขตบางเขน กรุงเทพฯ  10220</p>
                        <p>โทรศัพท์: 66 (0) 2 114 7989 , 66 (065) 146 4159</p>
                        <p>Email: Nadduean@whitespace.co.th</p></dl>
                        
                      </ol>
                      <div className="clearfix mb-4"></div>
                      <h4 className="text-center">นโยบายการคุ้มครองข้อมูลส่วนบุคคลนี้ประกาศไว้เมื่อวันที่ 25 พฤษภาคม 2565</h4>
                      <h4 className="text-center">(แก้ไขล่าสุด ณ วันที่ 24 มิถุนายน 2565)</h4>
                    <div className="row mb-5 mt-5">
                      <div className="col-12 text-end"><strong>(นายปกรณ์ พรรณเชษฐ์)</strong></div>
                      <div className="col-12 text-end"><strong>ประธานเจ้าหน้าที่บริหาร</strong></div>
                      <div className="col-12 text-end"><strong>บริษัท เดอะ ไวท์สเปซ จำกัด</strong></div>
                  </div>
            </div>
        </div>
    </div>
    <Footer/>
    
    </>
  );
}

export default PrivalcyNotices;
