import React, { useState,Component } from 'react';
import NumberFormat from 'react-number-format';
import Slider from "react-slick";
import Favorite from "../components/Favorite"
import { Button, Modal } from "react-bootstrap";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";
import axios from "axios"
export default function Item(props:any) {
    const data = props.asset;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);     
    
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:false
    };
    const getThumpnail=(url:string)=>{
      let filename = url.replace(/^.*[\\\/]/, '')
    return "https://admin.penguinasset.com/uploads/assetstore/"+data.code+"/thumbnail_"+filename;
    }
    getThumpnail(data.image)

    const share = async(shareWith:any) =>{
      const profile = sessionStorage.getItem('profile');
      handleClose()
      if(profile){
        const userdata = JSON.parse(profile);
          try {
              const response = await axios.post(
              `https://admin.penguinasset.com/api/shares`,{user_id:userdata.user.id,share:shareWith,asset_id:data.id}
              );
              console.log(response)
              handleClose()
          } catch (err:any) {
            handleClose()
          } finally {
            handleClose()
          }
      }
    }
    return (
      <div className='property-item w-100 mb-5 px-2'>
        <div className='item-img'>
            <div className={`badge badge-${data.announcement_type}`}><label>{data.announcementtypes_name}</label></div>
            {
                (data.sale_price>0)?
                <img src='../../promotion.png' alt='' className='promotion'/>
                :
                <></>
            }
            <a href={`/asset/${data.code+"/"+data.name.replace("/","-")}`}><img src={getThumpnail(data.image)} className='w-100 border-rounded'/></a>
        </div>
        <div className='row item-action'>
        <div className="col-12 col-sm-12 col-md-12 action text-end px-1">
            <NumberFormat decimalScale={0} value={(parseInt(data.viewed)>1000)?parseInt(data.viewed)/1000:parseInt(data.viewed)} displayType={'text'} suffix={(parseInt(data.viewed)>1000)?"k":""} thousandSeparator={true} />  Views <img src='../../icon/view.png' className='view'/><Favorite item={data}/><a className='cursor-pointer' onClick={()=>handleShow()}><img src='../../icon/open.png' className='open'/></a>
            </div>
        </div>
        <div className='item-title'><a href={`/asset/${data.code+"/"+data.name.replace("/","-")}`}>{data.name}</a></div>
        <div className='item-type'>ประเภทสินทรัพย์ : {data.assettypes_name}</div>
        <div className='item-location mb-3'>สถานที่ตั้ง : {data.locality}</div>
        <div className='item-desc py-2'>
            <div className='row'>
                <div className="col-3 text-center addon px-0"><img src='../../icon/size.png'/><label>{(data.asset_type.toString()==="3")?(data.land_area!==null)?data.land_area+' ตร.ม.':"- "+' ตร.ม.':data.land_area_price.replace("0-0-","")+' ตร.ว.'}</label></div>
                <div className="col-3 text-center addon"><img src='../../icon/toilet.png' alt=''/><label>{data.bathroom}</label></div>
                <div className="col-3 text-center addon"><img src='../../icon/bedroom.png'/><label>{data.bedroom}</label></div>
                <div className="col-3 text-center addon"><img src='../../icon/parking.png'/><label>{data.car_park}</label></div>
            </div>
        </div>
        <div className='row item-action pt-2'>
            <div className="col-12 col-sm-6 col-sm-6 price">
            ราคา <NumberFormat value={(data.sale_price>0)?data.sale_price:data.price} displayType={'text'} thousandSeparator={true} /> บาท
            </div>
           
            {
                  (data.sale_price>0)?
                  <div className="col-12 col-sm-6 col-sm-6 price ">
                   <div className="oldprice d-inline-block text-start w-auto p-0 font-18"><NumberFormat value={data.price} displayType={'text'} thousandSeparator={true} /> บาท</div>
                  </div>
                  :<></>
            }
        </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="py-1">
          <Modal.Title>แชร์</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <FacebookShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name.replace("/","-")}`}
            quote={data.name}
            className="mx-1"
            onClick={()=>share("facebook")}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          
          <LineShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name.replace("/","-")}`}
            title={data.name}
            className="mx-1"
            onClick={()=>share("line")}
          >
            <LineIcon size={32} round />
          </LineShareButton>
          <TwitterShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name.replace("/","-")}`}
            title={data.name}
            className="mx-1"
            onClick={()=>share("twitter")}
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <EmailShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name.replace("/","-")}`}
            subject={data.name}
            body="body"
            className="Demo__some-network__share-button"
          >
            <EmailIcon size={32} round onClick={()=>share("email")}/>
          </EmailShareButton>
      </Modal.Body>
      
      </Modal>
      </div>
      
    );
  }