import React,{ useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import '../index.css';
import Header from '../components/Header';
import {useParams} from "react-router-dom";
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../components/Footer';
import {Helmet} from "react-helmet";
import axios from "axios"
function Ourworks() {
  const { id } = useParams();
  const { name } = useParams();
  const [categorysData, setCategorysData] = useState([]);
  const [subCategorysData, setSubCategorysData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    
    const getData = async () => {
      try {
        const response = await axios.get(
          `https://admin.penguinasset.com/api/ourcategorys`);
          setCategorysData(response.data);
          getSubData();
          setError(null);
      } catch (err:any) {
        setError(err.message);
       
      } finally {
        setLoading(false);
      }
    };
    const getSubData = async () => {
      let url = "`https://admin.penguinasset.com/api/ourcategorys"
      
      if(id){
        url = "https://admin.penguinasset.com/api/ourcategorys/"+id+"/"+name;
      }else{
        url = "https://admin.penguinasset.com/api/ourcategorys/1/ผลงานทั้งหมด";
      }
      try {
        const response = await axios.get(url);
          setSubCategorysData(response.data);
          setError(null);
      } catch (err:any) {
        setError(err.message);
       
      } finally {
        setLoading(false);
      }
    };
    if(categorysData.length===0){
      getData();
    }
    
  }, []);
  return (
    <>
    <Helmet>
      <meta name="description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"/>
      <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
      เพื่อให้ “ใครๆ ก็มีบ้านได้”" />
      <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
    </Helmet>
   <Header />
   <div className='container-fuild our-works'>
      <div className='container pt-4 pb-5'>
          <h1 className='text-uppercase text-center text-white'>Our works</h1>
          <div className='row mt-5 pb-4'>
          {
            (categorysData.length>0&&!loading)?
            categorysData&&categorysData.map((item:any, index:number) => (
            <div className='col text-center ourworksType'>
              <a href={`/ourworks/${item.id}/${item.name}`}>
                <img src={`https://admin.penguinasset.com${item.icon}`}/>
                <label>{item.name}</label>
              </a>
            </div>
            ))
            :<></>
          }
        </div>
      </div>
   </div>
    <div className='container mt-5'>
        <div className="row mb-3">
          <div className='col-12 text-center'><h2>{(name)?name:"ผลงานทั้งหมด"}</h2></div>
        </div>
        <div className="row mb-5">
        {
            (subCategorysData.length>0&&!loading)?
            subCategorysData&&subCategorysData.map((item:any, index:number) => (
                  <div className="col-12 col-sm-6 col-md-4 mb-5 px-5 py-2">
                    <a href={`/category/${item.id}/${item.name}`}>
                    <div className="flip-container">
                      <div className="flipper">
                        <div className="front">
                        <img src={`https://admin.penguinasset.com${item.icon}`} className="w-100"/>
                        </div>
                        <div className="back">
                          <div className="flip_title">{item.name}</div>
                        </div>
                      </div>
                    </div>
                    </a>
                  </div>
            ))
            :<></>
        }
        </div>
    </div>
    <Footer/>
    </>
  );
}
export default Ourworks;
