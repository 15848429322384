import React,{ useState, useEffect,useRef } from "react";
import ReactDOM from 'react-dom/client';
import '../index.css';
import Header from '../components/Header';
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from '../components/Banner';
import PropertyList from '../components/PropertyList';
import { DropdownButton,Dropdown,Button } from 'react-bootstrap';
import Footer from '../components/Footer';
import axios from "axios"
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {useParams} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import {Helmet} from "react-helmet";
function valuetext(value: number) {
    return `${value}`;
  }
  let minprice = "ราคาเริ่มต้น";
  let maxprice = "ราคาสูงสุด";
  
function AssetsStore() {
  const [announcementtypes, setAnnouncementtypes] = useState([]);
   const [provinces, setProvinces] = useState([]);
    const [amphur, setAmphur] = useState([]);
    const [district, setDistrict] = useState([]);
    const [bedroomText, setBedroomText] = useState("");
    const [assetData, setAssetData] = useState([]);
    const [allData, setAllData] = useState({current_page:0,last_page:0,total:0});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filterStatus, setFilterStatus] = useState(false);
    const [filter, setFilter] = useState({assettype:"ประเภทสินทรัพย์",announcement:"ประเภทประกาศ",province:"จังหวัด",amphur:"เขต (อำเภอ)",district:"แขวง (ทำเล)",room:"ห้องนอน",minprice:"ราคาเริ่มต้น",maxprice:"ราคาสูงสุด",keyword:""});
    const [value, setValue] = React.useState<number[]>([1000000, 5000000]);
    const [loader, setLoader] = useState(false);
    const focusDiv:any = useRef();
    const handleChange = (event: Event, newValue: number | number[]) => {
      setValue(newValue as number[]);
      let price = value;
      minprice = price[0].toString();
      maxprice = price[1].toString();
      setFilter(oldValues=>({...oldValues,['minprice']:minprice.toString()}))
      setFilter(oldValues=>({...oldValues,['maxprice']:maxprice.toString()}))
    };
    const scrollToRef = (ref:any) => {
      window.scrollTo(0, ref.current.offsetTop);  
    }
    const setFilterValue = (name:string,value:any)=>{
      setFilter(oldValues=>({...oldValues,[name]:value}))
      if(name==="province"){
        getAmphur(value);
      }
      if(name==="amphur"){
        getDistrict(value);
      }
      if(name==="room"){
        if(value>4){
          setBedroomText("มากกว่า 4 ห้องนอน")
        }else{
          setBedroomText(value)
        }
      }
      setFilterStatus(true)
    }
    const setSearchValue = (e:any)=>{
      const {value,name} = e.target
      setFilter(oldValues=>({...oldValues,[name]:value}))
      
    }
    const hendleEnterSearch = (e:any)=>{
      if (e.key === 'Enter') {
        getFilter()
      }
      
    }
    const getAnnouncementtypes = async () => {
      try {
        const response = await axios.get(
          `https://admin.penguinasset.com/api/announcementtypes`);
        setAnnouncementtypes(response.data);
        setError(null);
      } catch (err:any) {
        setError(err.message);
       
      } finally {
       // setLoading(false);
      }
     
    };
    const getProvinces = async () => {
      try {
        const response = await axios.get(
          `https://admin.penguinasset.com/api/provinces`);
          setProvinces(response.data);
        setError(null);
      } catch (err:any) {
        setError(err.message);
       
      } finally {
        //setLoading(false);
      }
    };
    const getAmphur = async (pname:string) => {
      try {
        const response = await axios.get(
          `https://admin.penguinasset.com/api/amphures/`+pname);
          setAmphur(response.data);
          setError(null);
      } catch (err:any) {
        setError(err.message);
       
      } finally {
        //setLoading(false);
      }
    };
    const getDistrict = async (apname:string) => {
      try {
        const response = await axios.get(
          `https://admin.penguinasset.com/api/districts/`+apname);
          setDistrict(response.data);
          setError(null);
      } catch (err:any) {
        setError(err.message);
       
      } finally {
       // setLoading(false);
      }
    };
    const getFilter = async ()=>{
      setLoader(true)
      try {
        const response = await axios.get(
          `https://admin.penguinasset.com/api/assetstores`,
          { params: filter });
        setAssetData(response.data.data);
        setAllData(response.data);
        setError(null);
        setLoader(false)
        scrollToRef(focusDiv)
      } catch (err:any) {
        setError(err.message);
        setLoader(false)
      } finally {
        setLoading(false);
        setLoader(false)
      }
      setFilterStatus(true)
    }
    const handlePageClick = async (event:any) => {
      // const newOffset = (event.selected * itemsPerPage) % items.length;
     setLoader(true)
      
      try {
        const response = await axios.get(
          `https://admin.penguinasset.com/api/assetstores?page=`+event.selected,
          { params: filter });
        setAssetData(response.data.data);
        setAllData(response.data);
        setError(null);
        scrollToRef(focusDiv)
        setLoader(false)
      } catch (err:any) {
        setError(err.message);
        setLoader(false)
      } finally {
        setLoading(false);
        setLoader(false)
      }
    };
    const clearFilter=()=>{
      setFilter({assettype:"ประเภทสินทรัพย์",announcement:"ประเภทประกาศ",province:"จังหวัด",amphur:"เขต (อำเภอ)",district:"แขวง (ทำเล)",room:"ห้องนอน",minprice:"ราคาเริ่มต้น",maxprice:"ราคาสูงสุด",keyword:""})
      window.location.reload();
    }
    useEffect(() => {
      if(provinces.length===0){
        getProvinces();
        getAnnouncementtypes();
      }
      
      const getData = async () => {
        try {
          const response = await axios.get(
            `https://admin.penguinasset.com/api/assetstores`,
            { params: filter });
          setAssetData(response.data.data);
          setAllData(response.data);
          setError(null);
        } catch (err:any) {
          setError(err.message);
         
        } finally {
          setLoading(false);
        }
      };
      if(assetData.length===0){
        getData();
      }
      
    }, []);
    return (
      <>
      <Helmet>
      <meta name="description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"/>
      <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
      เพื่อให้ “ใครๆ ก็มีบ้านได้”" />
      <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
    </Helmet>
      <Header/>
      {/* <Banner/> */}
      <div className='container mt-5'>
        <div className='search-block mb-5'>
          <div className='row'>
            <div className='col-12 col-sm-12  col-md-12 col-lg-7'>
              <div className='row'>
               <div className='col text-center'>
                  <a href="./assetsstore"><img src='../icon/all.png'/><br/>สินทรัพย์</a>
                </div>
                <div className='col text-center'>
                  <a href="./type/บ้านเดี่ยว"><img src='../icon/home.png'/><br/>บ้านเดี่ยว</a>
                </div>
                <div className='col text-center'>
                  <a href="./type/ทาวน์เฮ้าส์"><img src='../icon/townhome.png'/><br/>ทาวน์เฮ้าส์</a>
                </div>
                <div className='col text-center'>
                 <a href="./type/คอนโด"><img src='../icon/condo.png'/><br/>คอนโดมิเนียม</a>
                </div>
                <div className='col text-center'>
                 <a href="./type/อาคารพาณิชย์"><img src='../icon/tower.png'/><br/>อาคารพาณิชย์</a>
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-12  col-md-12 col-lg-5'>
              <div className='search-full'>
              <input type="text" className="text-search" name="keyword" onChange={setSearchValue} onKeyDown={hendleEnterSearch} placeholder='เช่น รหัสสินทรัพย์, ทำเล, พื้นที่ใกล้เคียง'/>
                <img src='../icon/search.png' className='btn-search cursor-pointer' onClick={()=>getFilter()} />
                <img src="../penguin/penguin9.png" className="p-absolute penguin-9"/>
              </div>
            </div>
          </div>
          
        </div>
        <div className="row mb-4 filter-block mrb-0" ref={focusDiv}>
          <div className="col-12 col-sm-6 col-md-3 mb-4">
          <DropdownButton id="dropdown-item-button" title={filter.assettype} className='ddl-penguin'>
            <Dropdown.Item as="button" onClick={()=>setFilterValue("assettype","บ้านเดี่ยว")}>บ้านเดี่ยว</Dropdown.Item>
            <Dropdown.Item as="button" onClick={()=>setFilterValue("assettype","ทาวน์เฮ้าส์")}>ทาวน์เฮ้าส์</Dropdown.Item>
            <Dropdown.Item as="button" onClick={()=>setFilterValue("assettype","คอนโด")}>คอนโดมิเนียม</Dropdown.Item>
            <Dropdown.Item as="button" onClick={()=>setFilterValue("assettype","อาคารพาณิชย์")}>อาคารพาณิชย์</Dropdown.Item>
          </DropdownButton>
          </div>
          <div className="col-12 col-sm-6 col-md-3 mb-4">
          <DropdownButton id="dropdown-item-button" title={filter.announcement} className='ddl-penguin'>
          {
                announcementtypes.map((item:any,index:number)=>
                <Dropdown.Item as="button" key={`a-${index}`}  onClick={()=>setFilterValue("announcement",item.name)}>{item.name}</Dropdown.Item>
            )}
          </DropdownButton>
          </div>
          <div className="col-12 col-sm-6 col-md-3 mb-4">
          <DropdownButton id="dropdown-item-button" title={filter.province} className='ddl-penguin'>
            {
                provinces.map((item:any,index:number)=>
                <Dropdown.Item as="button" key={`a-${index}`}  onClick={()=>setFilterValue("province",item.name_th)}>{item.name_th}</Dropdown.Item>
            )}
          </DropdownButton>
          </div>
          <div className="col-12 col-sm-6 col-md-3 mb-4">
          <DropdownButton id="dropdown-item-button" title={filter.amphur} className='ddl-penguin'>
            {
                amphur.map((item:any,index:number)=>
                <Dropdown.Item as="button" key={`b-${index}`}  onClick={()=>setFilterValue("amphur",item.name_th)}>{item.name_th}</Dropdown.Item>
            )}
          </DropdownButton>
          </div>
          <div className="col-12 col-sm-6 col-md-3 mb-4">
          <DropdownButton id="dropdown-item-button" title={filter.district}  className='ddl-penguin'>
            {
                district.map((item:any,index:number)=>
                <Dropdown.Item as="button" key={`c-${index}`} onClick={()=>setFilterValue("district",item.name_th)}>{item.name_th}</Dropdown.Item>
            )}
          </DropdownButton>
          </div>

          <div className="col-12 col-sm-6 col-md-3 mb-4">
          <DropdownButton id="dropdown-item-button" title={`${bedroomText} ห้องนอน`} className='ddl-penguin'>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("room",1)}>1 ห้องนอน</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("room",2)}>2 ห้องนอน</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("room",3)}>3 ห้องนอน</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("room",4)}>4 ห้องนอน</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("room",5)}>มากกว่า 4 ห้องนอน</Dropdown.Item>
          </DropdownButton>
          </div>
          {/* <div className="col-12 col-sm-6 col-md-3 mb-4">
          <DropdownButton id="dropdown-item-button" title={filter.minprice} className='ddl-penguin'>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",500000)}>500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",1000000)}>1,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",1500000)}>1,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",2000000)}>2,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",2500000)}>2,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",3000000)}>3,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",3500000)}>3,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",4000000)}>4,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",4500000)}>4,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",5000000)}>5,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",5500000)}>5,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",6000000)}>6,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",6500000)}>6,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",7000000)}>7,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",7500000)}>7,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",8000000)}>8,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",8500000)}>8,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",9000000)}>9,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",9500000)}>9,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("minprice",10000000)}>10,000,000</Dropdown.Item>
          </DropdownButton>
          </div>
          <div className="col-12 col-sm-6 col-md-3 mb-4">
          <DropdownButton id="dropdown-item-button" title={filter.maxprice} className='ddl-penguin'>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",500000)}>500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",1000000)}>1,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",1500000)}>1,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",2000000)}>2,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",2500000)}>2,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",3000000)}>3,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",3500000)}>3,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",4000000)}>4,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",4500000)}>4,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",5000000)}>5,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",5500000)}>5,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",6000000)}>6,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",6500000)}>6,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",7000000)}>7,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",7500000)}>7,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",8000000)}>8,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",8500000)}>8,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",9000000)}>9,000,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",9500000)}>9,500,000</Dropdown.Item>
            <Dropdown.Item as="button"  onClick={()=>setFilterValue("maxprice",10000000)}>10,000,000</Dropdown.Item>
          </DropdownButton>
          </div> */}
          <div className="col-12 col-sm-12 col-md-6 mb-4">
          <Box sx={{ width: '100%' }} className="boxSlider">
            <label className="lb_start">0 บาท</label>
            <Slider
              getAriaLabel={() => 'Price range'}
              value={value}
              min={0}
              step={100000}
              max={10000000}
              onChange={handleChange}
              valueLabelDisplay="on"
              getAriaValueText={valuetext}
            />
            <label className="lb_end">10 ลบ.</label>
          </Box>
          </div>
        </div>        
          <div className='row mb-5 col-seach'>
            <div className='col text-center'></div>
            <div className='col text-center'></div>
            <div className='col text-center p-relative'>
            <img src="../penguin/penguin20.png" className="p-absolute penguin-20"/>
            <Button className='btn-penguin w-100' onClick={()=>getFilter()}>ค้นหา</Button>
          </div>
          { 
          (filterStatus)?
          <>
          <div className='col text-left'><span className="clearFilter" onClick={()=>clearFilter()}>ล้างข้อมูล</span></div>
          <div className='col text-center'></div>
          </>
            :
            <div className='col text-center'></div>
          }  
            <div className='col text-center'></div>
          </div>
        <div className='property-list mb-5'>
          <div className='row mb-5'>
            <div className='col-6 text-start asset-group-seleted d-flex align-items-end'>
                <img src='../icon/all-r.png'/>
                <label className="ms-3">สินทรัพย์</label>
            </div>
            <div className='col-6 justify-content-end asset-group d-flex align-items-end'>
                    <a href="../type/บ้านเดี่ยว"><img src='../icon/home-b.png'/><label>บ้านเดี่ยว</label></a>
                    <a href="../type/ทาวน์เฮ้าส์"><img src='../icon/townhome-b.png'/><label>ทาวน์เฮ้าส์</label></a>
                    <a href="../type/คอนโด"><img src='../icon/condo-b.png'/><label>คอนโดมิเนียม</label></a>
                    <a href="../type/อาคารพาณิชย์"><img src='../icon/tower-b.png'/><label>อาคารพาณิชย์</label></a>
            </div>
          </div>
          <div className='container propertyList p-0' >

          {
            (assetData.length>0&&!loading)?
              assetData&&assetData.map((item:any, index:number) => (
                <PropertyList asset={item}/>
              ))
             : 
             <>
             {
              (!loading)?
              <div className="none-data"><img src='../icon/home-r.png' className="mb-2"/><br/>ไม่พบข้อมูล</div>  
              :
              <></>
             }
             </>
            }
          </div>
          {
            (assetData.length>0&&!loading)?
          <div className="row">
              <div className="col-12 text-center page-summary">
                  {allData?.current_page}-  {allData?.last_page} จากทั้งหมด {allData?.total}
              </div>
              <div className="col-12 text-center">
                  <nav aria-label="Page navigation example m-auto">
                  {/* <ul className="pagination justify-content-center">
                          <li className="page-item"><a className="page-link" href="#"><img src='../icon/prev.png'/></a></li>
                          <li className="page-item"><a className="page-link" href="#">1</a></li>
                          <li className="page-item"><a className="page-link" href="#">2</a></li>
                          <li className="page-item"><a className="page-link" href="#">3</a></li>
                          <li className="page-item"><a className="page-link" href="#"><img src='../icon/next.png'/></a></li>
                      </ul> */}
                  <ReactPaginate
                     nextLabel=" "
                     onPageChange={handlePageClick}
                     pageRangeDisplayed={3}
                     marginPagesDisplayed={2}
                     pageCount={allData?.last_page}
                     previousLabel=" "
                     pageClassName="page-item"
                     pageLinkClassName="page-link"
                     previousClassName="page-item"
                     previousLinkClassName="page-link"
                     nextClassName="page-item"
                     nextLinkClassName="page-link"
                     breakLabel="..."
                     breakClassName="page-item"
                     breakLinkClassName="page-link"
                     containerClassName="pagination"
                     activeClassName="active"
                />
                  </nav>
              </div>
              
          </div>
          :
          <></>
                    }
        </div>
    </div>
    <Footer/>
    {
      (loader)?
        <div className="overlay">
          <div className="custom-loader"></div>
        </div>
        :
        <></>
    }
    </>
  );
}

export default AssetsStore;
