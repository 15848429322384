import React, { useState,Component } from 'react';
import NumberFormat from 'react-number-format';
import Favorite from "../components/Favorite"
import { Button, Modal } from "react-bootstrap";
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon,
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
  } from "react-share";
  import axios from "axios"
export default function PropertyList(props:any) {
    const data = props.asset;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true); 
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:false
    };
    const numberWithCommas=(x:any)=> {
      console.log("number=>",x);
      if(x!=="undefined"){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
    const share = async(shareWith:any) =>{
      const profile = sessionStorage.getItem('profile');
      handleClose()
      if(profile){
        const userdata = JSON.parse(profile);
          try {
              const response = await axios.post(
              `https://admin.penguinasset.com/api/shares`,{user_id:userdata.user.id,share:shareWith,asset_id:data.id}
              );
              console.log(response)
              handleClose()
          } catch (err:any) {
            handleClose()
          } finally {
            handleClose()
          }
      }
    }
    return (
      <div className='row property-list-item w-100 m-0 '>
        <div className='col-12 col-sm-12 col-md-3 item-img'>
        <div className='item-img'>
            <div className={`badge badge-${data.announcement_type}`}><label>{data.announcementtypes_name}</label></div>
            {
                (data.sale_price>0)?
                <img src='../../promotion.png' alt='' className='promotion'/>
                :
                <></>
            }
            <a href={`/asset/${data.code+"/"+data.name.replace("/","-")}`}><img src={`https://admin.penguinasset.com${data.image}`} className='w-100 border-rounded'/></a>
        </div>
        </div>
        <div className='col-12 col-sm-12 col-md-6'>
            <div className='item-title'><a href={`/asset/${data.code+"/"+data.name.replace("/","-")}`}>{data.name}</a></div>
            <div className='item-short mb-3 pe-5'>{data.excerpt}</div>
            <div className='item-type'>ประเภทสินทรัพย์ : {data.assettypes_name}</div>
            <div className='item-location'>สถานที่ตั้ง : {data.locality}</div>
            <div className='item-code'>รหัสสินทรัพย์ : {data.code}</div>
            <div className='item-post-date'>ประกาศวันที่ {data.created_at}</div>
        </div>
        <div className='col-12 col-sm-12 col-md-3 item-desc'>
            <div className="row">
                <div className="col-12 text-center price">
                    ราคา <NumberFormat value={(data.sale_price&&data.sale_price>0)?data.sale_price:data.price} displayType={'text'} thousandSeparator={true} /> บาท
                </div>
                {
                  (data.sale_price&&data.sale_price>0)?
                  <div className="oldprice d-block my-2 mx-auto w-auto p-0"><NumberFormat value={data.price} displayType={'text'} thousandSeparator={true} /> บาท</div>
                  :<></>
                }
            </div>
            <div className='row'>
                <div className="col-3 text-center addon px-0"><img src='../icon/size.png'/><label>{(data.asset_type.toString()==="3")?(data.land_area!==null)?data.land_area+' ตร.ม.':"- "+' ตร.ม.':data.land_area_price.replace("0-0-","")+' ตร.ว.'}</label></div>
                <div className="col-3 text-center addon"><img src='../icon/toilet.png'/><label>{data.bathroom}</label></div>
                <div className="col-3 text-center addon"><img src='../icon/bedroom.png'/><label>{data.bedroom}</label></div>
                <div className="col-3 text-center addon"><img src='../icon/parking.png'/><label>{data.car_park}</label></div>
            </div>
            <div className='row item-action'>
                <div className="col-12 action text-end ">
                <NumberFormat decimalScale={0} value={(parseInt(data.viewed)>1000)?parseInt(data.viewed)/1000:parseInt(data.viewed)} displayType={'text'} suffix={(parseInt(data.viewed)>1000)?"k":""} thousandSeparator={true} /> Views <img src='../icon/view.png' className='view'/><Favorite item={data}/><a className='cursor-pointer' onClick={()=>handleShow()}><img src='../../icon/open.png' className='open'/></a>
                </div>
            </div>
        </div>
        <div className="col-12 mt-5">
            <div className='w-100 sepetor'></div>
        </div>
        <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="py-1">
          <Modal.Title>แชร์</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
        <FacebookShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name.replace("/","-")}`}
            quote={data.name}
            className="mx-1"
            onClick={()=>share("facebook")}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          
          <LineShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name.replace("/","-")}`}
            title={data.name}
            className="mx-1"
            onClick={()=>share("line")}
          >
            <LineIcon size={32} round />
          </LineShareButton>
          <TwitterShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name.replace("/","-")}`}
            title={data.name}
            className="mx-1"
            onClick={()=>share("twitter")}
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          {/* <LinkedinShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name.replace("/","-")}`}
            title={data.name}
            className="mx-1"
            onClick={()=>share("linkin")}
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <PinterestShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name.replace("/","-")}`}
            media  ={`https://penguinasset.com${data.image}`}
            description  ={data.excerpt}
            className="mx-1"
            onClick={()=>share("pinterest")}
          >
            <PinterestIcon size={32} round />
          </PinterestShareButton> */}
          <EmailShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name.replace("/","-")}`}
            subject={data.name}
            body="body"
            className="Demo__some-network__share-button"
          >
            <EmailIcon size={32} round onClick={()=>share("email")}/>
          </EmailShareButton>
      </Modal.Body>
      
      </Modal>
      </div>
    );
  }