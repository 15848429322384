export function ValidatePhoneNumber(phone:string) {
    let err:string=""
    if (phone !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(phone)) {
    
        err = "กรุณากรอกเบอร์โทรเป็นตัวเลขเท่านั้น";
    
      }else if(phone.length < 10){
    
        err = "กรุณากรอกเบอร์โทรให้ถูกต้อง";
    
      }
    
    }
    return err;
  }
export function ValidateEmail(email:string) {
    let err:string=""
    if (email !== "undefined") {
      var pattern = new RegExp(/\S+@\S+\.\S+/);
      if (!pattern.test(email)) {
        err = "กรุณากรอกอีเมล์ให้ถูกต้อง";
      }
    }
    return err;
  }
  