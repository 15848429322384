import React,{ useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import '../index.css';
import Header from '../components/Header';
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from '../components/Banner';
import Item from '../components/Item';
import { DropdownButton,Dropdown,Button } from 'react-bootstrap';
import Footer from '../components/Footer';
import axios from "axios"
import {Helmet} from "react-helmet";

function Favorites() {
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profile, setProfile] = useState({access_token:"",user:{email:""}});
  let favoriteItem:any = null;
  if(sessionStorage.getItem('favoriteItem')!== null){
      favoriteItem = sessionStorage.getItem('favoriteItem');
  }
  if(sessionStorage.getItem('profile')=== null){
    window.location.href="/"
  }
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `https://admin.penguinasset.com/api/favorites`,{params:{favorite:favoriteItem}});
        setData(response.data);
        setError(null);
      } catch (err:any) {
        setError(err.message);
       
      } finally {
        setLoading(false);
      }
    };
    if(data.length===0){
      getData();
    }
    if(sessionStorage.getItem('profile')!== null){
        let login:any =JSON.parse(sessionStorage.getItem('profile')!);
        setProfile(login)

      }
  }, []);
  return (
    <>
    <Helmet>
      <meta name="description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"/>
      <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
      เพื่อให้ “ใครๆ ก็มีบ้านได้”" />
      <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
    </Helmet>
    <Header/>
    <div className='container'>  
        <div className='row'>
            <div className="col-12 favorite-title">
                <h2 className="mb-0">ข้อมูลสมาชิก</h2>
                {profile.user.email}
            </div>
        </div>
        <div className='row mt-5'>
            <div className='col-12 mb-4'>
                <div className='relateItem'>สินทรัยพ์รายการโปรด</div>
            </div>
        </div>
        <div className='property-list mb-5'>
          <div className='row'>
           {
            (data.length>0)?
           data &&data.map((item:any, index:number) => (
              <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={index}><Item asset={item}/></div>
             ))
           
            :
            <>
            {
             (!loading)?
             <div className="none-data"><img src='../icon/home-r.png' className="mb-2"/><br/>ไม่พบข้อมูล</div>  
             :
             <></>
            }
            </>
          }
          </div>
        </div>
    </div>
    <Footer/>
    
    </>
  );
}

export default Favorites;
