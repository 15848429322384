import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './components/Header';
import './css/font.css';
import './scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter,Routes,Route} from 'react-router-dom';
import Home from './pages/Home';
import AssetStore from './pages/AssetStore';
import Asset from './pages/Asset';
import Aboutus from './pages/Aboutus';
import Ourworks from './pages/Ourworks';
import Worklist from './pages/Worklist';
import Blog from './pages/Blog';
import Blogdetail from './pages/Blogdetail';
import Contactus from './pages/Contact';
import Register from './pages/Register';
import Singin from './pages/Singin';
import Type from './pages/Type';
import Favorites from './pages/Favorites';
import Policy from './pages/Policy';
import Cookies from './pages/Cookies';
import PrivalcyNotices from './pages/PrivalcyNotices';
import Forgotpassword from './pages/Forgotpassword';
import Login from './pages/Login';
import Resetpassword from './pages/Resetpassword';
import Work from './pages/Work';
import Calculate_house_interest from './pages/Calculate_house_interest';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <BrowserRouter>
        <Routes>
          <Route  path="/" element={<Home/>}/>
          <Route  path="/assetsstore" element={<AssetStore/>}/>
          <Route  path="/asset/:code/:name" element={<Asset/>}/>
          <Route  path="/type/:typename" element={<Type/>}/>
          <Route  path="/aboutus" element={<Aboutus/>}/>
          <Route  path="/ourworks" element={<Ourworks/>}/>
          <Route  path="/ourworks/:id/:name" element={<Ourworks/>}/>
          <Route  path="/category/:id/:name" element={<Worklist/>}/>
          <Route  path="/work/:name" element={<Work/>}/>
          <Route  path="/blogs" element={<Blog/>}/>
          <Route  path="/blog/:name" element={<Blogdetail/>}/>
          <Route  path="/contactus" element={<Contactus/>}/>
          <Route  path="/consignment" element={<Register/>}/>
          <Route  path="/signin" element={<Singin/>}/>
          <Route  path="/favorites" element={<Favorites/>}/>
          <Route  path="/privacy-policy" element={<Policy/>}/>
          <Route  path="/cookies-policy" element={<Cookies/>}/>
          <Route  path="/policy-notiecs" element={<PrivalcyNotices/>}/>
          <Route  path="/forgotpassword" element={<Forgotpassword/>}/>
          <Route  path="/login" element={<Login/>}/>
          <Route  path="/Calculate_house_interest" element={<Calculate_house_interest/>}/>
          <Route  path="/password/reset/:token" element={<Resetpassword/>}/>
        </Routes>
    </BrowserRouter>
  </>
);
reportWebVitals();
