import { useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import '../index.css';
import Header from '../components/Header';
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../components/Footer';
import axios from "axios"
import { Button, Modal } from "react-bootstrap";
import {Helmet} from "react-helmet";
import { ValidatePhoneNumber,ValidateEmail} from "../services/Formatter";
import {useParams, useSearchParams} from "react-router-dom";
const responseGoogle = (response:any) => {
    console.log(response);
  }  
function Resetpassword() {
    const [emailParams, setEmailParams] = useSearchParams();
    const { token } = useParams();
    const [formInput, setFormInput] = useState({email: emailParams.get("email"),token:token,password:"",repassword:""});
    const [consent, setConsent] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [showErrorPasswordMessage, setShowPasswordErrorMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [EmailErrorMessage, setEmailErrorMessage] = useState(false);
    const [sending, setSending] = useState(false);
    const [emailErr, setEmailErr] = useState("");
    const setInputvalue=(e:any)=>{
        const {value,name} = e.target
        setFormInput(oldValues=>({...oldValues,[name]:value}))
          if(name==="email"){
            const erremail = ValidateEmail(value);
            setEmailErr(erremail);
          }
      }
      const setConsentvalue=(e:any)=>{
        if(e.target.checked){
          setConsent(true)
        }else{
          setConsent(false)
        }
        
      }
   
    const submitForm = async () => {
   
            if(formInput.email?.trim() !==""&&formInput.password.trim() !==""&&showErrorMessage!==true&&EmailErrorMessage!==true&&emailErr ===""){
                try {
                    const response = await axios.post(
                        `https://admin.penguinasset.com/api/auth/reset`,formInput
                    );
                    autoLogIn(formInput.email!,formInput.password)
                    setFormInput({email:"",password:"",repassword:"",token:""});
                    //setShow(!show)
                    
                } catch (err:any) {
                    setError(err.message);
                
                } finally {
                    setLoading(false);
                }
            }else{
            alert("กรุณากรอกข้อมูลให้ถูกต้อง!!")
            }
       
    };
    
    const autoLogIn = async (email:string,pass:string) => {

       
            try {
                const response:any = await axios.post(
                `https://admin.penguinasset.com/api/auth/login`,{email:email,password:pass}
                );
                sessionStorage.setItem("profile",JSON.stringify(response.data));
                window.location.href="/"
            } catch (err:any) {
                setError(true);
            
            } finally {
                setLoading(false);
            }
        
    };
      useEffect(() => {
        if (formInput.password !=="" && formInput.repassword!=="") {
            if (formInput.password !== formInput.repassword) {
                setShowErrorMessage(true);
            } else {
                setShowErrorMessage(false)
            }
        }
        if (formInput.password!==""&&formInput.password.length<8) {
            setShowPasswordErrorMessage(true);
        }else{
            setShowPasswordErrorMessage(false);
        }
    }, [formInput])
  return (
    <>
    <Helmet>
      <meta name="description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"/>
      <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
      เพื่อให้ “ใครๆ ก็มีบ้านได้”" />
      <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
    </Helmet>
   <Header />
    <div className='container contactus-form h-100vh pt-5'>
        <div className="row mb-5">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 contact-form mb-4 m-auto">
          <div className='login-box'>
            <h2 className='color-primary lh-28'>รีเซ็ตรหัสผ่าน</h2>
            {(error)?
                            <div className="text-danger text-warning alert alert-danger p-1">เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง </div>
                            :<></>
                    }
            <form >
                <div className="form-group mb-2">
                    <label htmlFor="email"><span className='text-danger'>*</span> Email</label>
                    <input type="email" className="form-control penguinInput" id="email" name='email' aria-describedby="emailHelp" placeholder="" onChange={setInputvalue}/>
                    {EmailErrorMessage ? <div className="text-danger"> Email address already exists. </div> : ''}
                    <div className="text-danger text-warning">{(emailErr!=="")? emailErr:""}</div>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="password"><span className='text-danger'>*</span> Password</label>
                    <input type="password" className="form-control penguinInput" id="password" name="password"  placeholder="" onChange={setInputvalue}/>
                    {(showErrorPasswordMessage) ? <div className="text-danger"> Password minimum 8 characters </div> : ''}
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="repassword"><span className='text-danger'>*</span> Confirm Password</label>
                    <input type="password" className="form-control penguinInput" id="repassword" name="repassword" placeholder="" onChange={setInputvalue}/>
                    {showErrorMessage ? <div className="text-danger"> Passwords did not match </div> : ''}
                </div>
                <div className='w-100 text-center mt-4'>
                    <button type="button" onClick={()=>submitForm()} className="btn btn-penguin text-white">ตกลง</button>
                </div>
            </form>
            </div>
          </div>
        </div>
    </div>
    {
      (sending)?
        <div className="overlay">
          <div className="custom-loader"></div>
        </div>
        :
        <></>
    }
    </>
  );
}

export default Resetpassword;
