import { useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import '../index.css';
import Header from '../components/Header';
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../components/Footer';
import {Helmet} from "react-helmet";
import axios from "axios"
import { Button, Modal } from "react-bootstrap";
import { ValidatePhoneNumber,ValidateEmail} from "../services/Formatter";
function Contactus() {
    const [consent, setConsent] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [error, setError] = useState(null);
    const [formInput, setFormInput] = useState({name:"",phone:"",email:"",message:"",asset_name:""});
    const [show, setShow] = useState(false);
    const [phoneErr, setPhoneErr] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const setInputvalue=(e:any)=>{
      const {value,name} = e.target
      setFormInput(oldValues=>({...oldValues,[name]:value}))
      if(name==="phone"){
        const errphone = ValidatePhoneNumber(value);
        setPhoneErr(errphone);
      }
      if(name==="email"){
        const erremail = ValidateEmail(value);
        setEmailErr(erremail);
      }
    }
    const setConsentvalue=(e:any)=>{
      if(e.target.checked){
        setConsent(true)
      }else{
        setConsent(false)
      }
      
    }
    const submitForm = async () => {
      if(formInput.name.trim() !==""&&formInput.email.trim() !==""&&formInput.phone.trim() !==""&&formInput.message.trim() !==""&&consent ===true&&phoneErr ===""&&emailErr ===""){
        setSending(true)
        try {
          const response = await axios.post(
            `https://admin.penguinasset.com/api/contacts`,formInput
          );
          setFormInput({name:"",phone:"",email:"",message:"",asset_name:""})
          setConsent(false)
          setShow(!show)
          setSending(false)
          setFormInput({name:"",phone:"",email:"",message:"",asset_name:""});
        } catch (err:any) {
          setError(err.message);
          setSending(false)
        
        } finally {
          setLoading(false);
          setSending(false)
          setSending(false)
          setFormInput({name:"",phone:"",email:"",message:"",asset_name:""});
        }
      }else{
        alert("กรุณากรอกข้อมูลให้ถูกต้อง!!")
      }
    };
   
  return (
    <>
    <Helmet>
      <meta name="description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"/>
      <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
      เพื่อให้ “ใครๆ ก็มีบ้านได้”" />
      <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
    </Helmet>
   <Header />
    <div className='container contactus-form h-100vh'>
        <div className="row mb-5">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 contact-form mb-4">
            <h2 className='color-primary lh-28 d-inline-block p-relative'>ติดต่อฝ่ายขาย <img src="../penguin/penguin8.png" className="p-absolute penguin-8"/></h2>
            <div className='w-100 mb-4'>กรอกรายละเอียด <small id="emailHelp" className="float-right form-text text-muted">(<span className='text-danger'>*</span> แสดงข้อมูลที่จำเป็นต้องใช้)</small></div>
            <form >
                <div className="form-group mb-2">
                    <label htmlFor="name"><span className='text-danger'>*</span> ชื่่อ</label>
                    <input onChange={setInputvalue} value={formInput.name} type="text" className="form-control penguinInput" id="name" name="name" aria-describedby="emailHelp" placeholder="ชื่่อ-นามสกุล"/>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="phone"><span className='text-danger'>*</span> เบอร์โทรศัพท์</label>
                    <input onChange={setInputvalue}  value={formInput.phone} type="tel" className="form-control penguinInput" id="phone" name="phone" placeholder="เบอร์โทรศัพท์"/>
                    <div className="text-danger text-warning">{(phoneErr!=="")? phoneErr:""}</div>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="email"><span className='text-danger'>*</span> อีเมล์</label>
                    <input onChange={setInputvalue}  value={formInput.email} type="email" className="form-control penguinInput" id="email" name="email" placeholder="อีเมล์"/>
                    <div className="text-danger text-warning">{(emailErr!=="")? emailErr:""}</div>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="asset_name"><span className='text-danger'>*</span> ประเภทสินทรัพย์</label>
                    <select className="form-control penguinInput penguinSelect" id='ddl_asset' name="asset_name" required  value={formInput.asset_name} onChange={setInputvalue} >
                        <option value="">-- ประเภทสินทรัพย์ --</option>
                        <option value="บ้านเดี่ยว">บ้านเดี่ยว</option>
                        <option value="ทาวน์เฮ้าส์">ทาวน์เฮ้าส์</option>
                        <option value="คอนโดมิเนียม">คอนโดมิเนียม</option>
                        <option value="อาคารพาณิชย์">อาคารพาณิชย์</option>
                        <option value="อื่นๆ">อื่นๆ</option>
                    </select>
                    {/* <input onChange={setInputvalue} type="text"  value={formInput.asset_name} className="form-control penguinInput" id="asset_name" name="asset_name" placeholder="ประเภททรัพย์"/> */}
                </div>
                <div className="form-group mb-4">
                    <label htmlFor="message">คำถามของคุณ (สามารถใส่ได้ไม่เกิน 300 ตัวอักษร)</label>
                    <textarea maxLength={300} onChange={setInputvalue}   value={formInput.message} className="form-control penguinInput" id="message" name="message" rows={3}  />
                </div>
                <div className="mb-4 text-left">
                <input type="checkbox" className="form-check-input" name="consent" id="consent"  onChange={setConsentvalue} checked={(consent)?true:false}/>
                <p className="form-check-label ms-2 policy-text d-inline text-left" >ข้าพเจ้าตกลงและยินยอมที่จะให้ บริษัท เดอะ ไวท์สเปช จำกัด เก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุลคลของข้าพเจ้า  ตามวัตถุประสงค์
ที่ได้กำหนดไว้ในนโยบายและประกาศการคุ้มครองข้อมูลของบริษัท บริษัทจะดำเนินการเท่าที่จำเป็นเพื่อรักษาข้อมูลส่วนบุลคลของท่าน
ตามกฎหมายคุ้มครองข้อมูลส่วนบุลคลที่เกี่ยวข้อง <a href="/policy-notiecs">นโยบายการคุ้มครองข้อมูลส่วนบุลคล</a></p>
                </div>
                <div className='w-100 text-center'>
                    <button type="button" className="btn btn-penguin text-white" onClick={()=>submitForm()}>ส่งข้อความ</button>
                </div>
            </form>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <h2 className='color-primary lh-28'>THE WHITESPACE COMPANY LIMITED</h2>
                <div className='addr'>
                    <img src='../icon/pin.png'/>
                    <p>
                    บริษัท เดอะ ไวท์สเปซ จำกัด <br/>
                    เลขที่ 87 โครงการเดอะแจส รามอินทรา ห้อง A302 <br/>
                    ชั้น 3 ถนนลาดปลาเค้า แขวงอนุสาวรีย์ <br/>
                    เขตบางเขน กรุงเทพฯ 10220
                    </p>
                </div>
                <div className='phone'>
                    <img src='../icon/phone.png'/>
                    <p>
                    <a href='tel:021147989' className='text-black'>02 114 7989</a>
                    </p>
                </div>
                <div className='mail'>
                    <img src='../icon/mail.png'/>
                    <p>
                        <a href='mailto:Penguinasset@whitespace.co.th'>Penguinasset@whitespace.co.th</a>
                    </p>
                    <a href="https://www.facebook.com/PenguinAsset/" target="_blank" className='c-facebook'><img src='../facebook.png'/></a>
                    <a href="tel:021147989" className='c-phone'><img src='../phone.png'/></a>
                    <a href="https://lin.ee/oK9IApV" target="_blank" className='c-line'><img src='../line.png'/></a>
                </div>
                <div className='w-100'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7747.23792169309!2d100.60576857089073!3d13.861896200000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29d517958cc45%3A0xff5a8877d0a1297!2sTHE%20WHITESPACE%20COMPANY%20LIMITED!5e0!3m2!1sen!2sth!4v1657466715790!5m2!1sen!2sth" width="100%" height="450" loading="lazy"></iframe>
                </div>
          </div>
        </div>
    </div>
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>ส่งข้อมูลเรียบร้อย</Modal.Title>
        </Modal.Header>
        <Modal.Body>ขอบคุณที่สนใจสินค้าของเรา ทางเราจะรีบติดต่อกลับหาท่านโดยเร็ว</Modal.Body>
        <Modal.Footer>
          <Button variant="primary px-4" onClick={handleClose}>
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>
    <Footer/>
    {
      (sending)?
        <div className="overlay">
          <div className="custom-loader"></div>
        </div>
        :
        <></>
    }
    </>
  );
}

export default Contactus;
