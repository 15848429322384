import React,{ useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import '../index.css';
import Header from '../components/Header';
import {useParams} from "react-router-dom";
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../components/Footer';
import {Helmet} from "react-helmet";
import axios from "axios"
import parse from 'html-react-parser'
import { ValidatePhoneNumber} from "../services/Formatter";
function Work() {
  const { id } = useParams();
  const { name } = useParams();
  const [categorysData, setCategorysData] = useState([]);
  const [subCategorysData, setSubCategorysData] = useState([]);
  const [detail, setDetail] = useState({id:"",name:"",expert:"",description:"",image:"",created_by:""});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [phoneErr, setPhoneErr] = useState("");
  const [sending, setSending] = useState(false);
  const [formInput, setFormInput] = useState({name:"",phone:"",message:"",asset_name:""});
  const [consent, setConsent] = useState(false);
  const [show, setShow] = useState(false);
  const setInputvalue=(e:any)=>{
    const {value,name} = e.target
    setFormInput(oldValues=>({...oldValues,[name]:value}))
    if(name==="phone"){
      const errphone = ValidatePhoneNumber(value);
      setPhoneErr(errphone);
    }
  }
  const setConsentvalue=(e:any)=>{
    if(e.target.checked){
      setConsent(true)
    }else{
      setConsent(false)
    }
    
  }
  const submitForm = async () => {
    if(formInput.name.trim() !==""&&formInput.phone.trim() !==""&&formInput.message.trim() !==""&&consent ===true&&phoneErr ===""){
      setSending(true)
      try {
        const response = await axios.post(
          `https://admin.penguinasset.com/api/workmessage`,formInput
        );
        setFormInput({name:"",phone:"",message:"",asset_name:""})
        setConsent(false)
        setShow(!show)
        setSending(false)
      } catch (err:any) {
        setError(err.message);
        setSending(false)
      
      } finally {
        setLoading(false);
        setSending(false)
      }
    }else{
      alert("กรุณากรอกข้อมูลให้ถูกต้อง!!")
    }
  };
  useEffect(() => {
    const getDetail = async () => {
      try {
        const response = await axios.get(
          `https://admin.penguinasset.com/api/work/`+name);
          setDetail(response.data);
          setError(null);
      } catch (err:any) {
        setError(err.message);
       
      } finally {
        setLoading(false);
      }
    };
    const getData = async () => {
      try {
        const response = await axios.get(
          `https://admin.penguinasset.com/api/ourcategorys`);
          setCategorysData(response.data);
          setError(null);
      } catch (err:any) {
        setError(err.message);
       
      } finally {
        setLoading(false);
      }
    };
     
    if(categorysData.length===0){
      getData();
    }
    getDetail();
  }, []);
  return (
    <>
    <Helmet>
      <meta name="description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"/>
      <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
      เพื่อให้ “ใครๆ ก็มีบ้านได้”" />
      <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
    </Helmet>
   <Header />
   <div className='container-fuild our-works'>
      <div className='container pt-4 pb-5'>
          <h1 className='text-uppercase text-center text-white'>Our works</h1>
          <div className='row mt-5 pb-4'>
          {
            (categorysData.length>0&&!loading)?
            categorysData&&categorysData.map((item:any, index:number) => (
            <div className='col text-center ourworksType'>
              <a href={`/ourworks/${item.id}/${item.name}`}>
                <img src={`https://admin.penguinasset.com${item.icon}`}/>
                <label>{item.name}</label>
              </a>
            </div>
            ))
            :<></>
          }
        </div>
      </div>
   </div>
    <div className='container mt-5'>
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2>{detail.name}</h2>
          </div>
          <div className="col-12">
            <img src={`https://admin.penguinasset.com${detail.image}`} alt={detail.name} className="w-100"/>
          </div>
          <div className="col-12 py-4">
            <p>{(detail.description)?parse(detail.description!):""}</p>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 contact-pannel ps-0">
                <div className="w-100 p-relative button-contact">
                    <img src="../../penguin/penguin22.png" className="penguin-22"/>
                    <div className='asset-contact'>
                    ติดต่อฝ่ายขาย  <a href={`tel:0954799451`}> <img src='../../icon/tel.png' />095 479 9451</a>
                    </div>
                </div>
                <div className="w-100 text-center">
                    <p>หรือต้องการให้เจ้าหน้าที่ติดต่อกลับ กรุณากรอกข้อมูล</p>
                </div>
                <div className="w-100">
                <form >
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <div className="form-group mb-4">
                          <label htmlFor="name"><span className='text-danger'>*</span> ชื่อ-นามสกุล</label><small id="nameHelp" className="float-right form-text text-muted">(<span className='text-danger'>*</span> แสดงข้อมูลที่จำเป็นต้องใช้)</small>
                          <input type="text" onChange={setInputvalue} value={formInput.name} className="form-control penguinInput" name="name" id="name" aria-describedby="nameHelp" placeholder="ชื่อ-นามสกุล"/>
                      </div>
                      <div className="form-group mb-4">
                          <label htmlFor="phone"><span className='text-danger'>*</span> เบอร์โทรศัพท์</label>
                          <input type="text" onChange={setInputvalue} value={formInput.phone} className="form-control penguinInput" name="phone" id="phone" placeholder="เบอร์โทรศัพท์"/>
                          <div className="text-danger text-warning">{(phoneErr!=="")? phoneErr:""}</div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                    <div className="form-group mb-4">
                        <label htmlFor="message"><span className='text-danger'>*</span> ข้อความ</label>
                        <textarea onChange={setInputvalue}  className="form-control penguinInput work-text" name="message" id="message" placeholder="ข้อความ" value={formInput.message}>{formInput.message}</textarea>
                    </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                    <div className="mb-4 text-left work-policy">
                        <input type="checkbox" className="form-check-input" name="consent" id="consent"  onChange={setConsentvalue} checked={(consent)?true:false}/>
                        <p className="form-check-label ms-2 policy-text d-inline" >ข้าพเจ้าตกลงและยินยอมที่จะให้ บริษัท เดอะ ไวท์สเปช จำกัด เก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุลคลของข้าพเจ้า  ตามวัตถุประสงค์
                          ที่ได้กำหนดไว้ในนโยบายและประกาศการคุ้มครองข้อมูลของบริษัท บริษัทจะดำเนินการเท่าที่จำเป็นเพื่อรักษาข้อมูลส่วนบุลคลของท่าน
                          ตามกฎหมายคุ้มครองข้อมูลส่วนบุลคลที่เกี่ยวข้อง <a href="/policy-notiecs">นโยบายการคุ้มครองข้อมูลส่วนบุลคล</a></p>
                    </div>
                    <div className='w-100 text-center'>
                    <button type="button" onClick={()=>submitForm()} className="btn btn-penguin text-white">ส่งข้อความ</button>
                    </div>
                    </div>
                    </div>
                </form>
                </div>
          </div>
        </div>
    </div>
    <Footer/>
    {
      (sending)?
        <div className="overlay">
          <div className="custom-loader"></div>
        </div>
        :
        <></>
    }
    </>
  );
}
export default Work;
