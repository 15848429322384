import {useRef,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import '../index.css';
import Header from '../components/Header';
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from '../components/Banner';
import PropertyItem from '../components/PropertyItem';
import { DropdownButton,Dropdown,Button } from 'react-bootstrap';
import Footer from '../components/Footer';
import {Helmet} from "react-helmet";
import { setTimeout } from 'timers/promises';
function Aboutus() {
  const focusDiv:any = useRef();
  const whyDiv:any = useRef();
  const scrollToRef = (ref:any) => {
    window.scrollTo(0, ref.current.offsetTop-108);  
  }
  const scrollToRefWhy = (ref:any) => {
    window.scrollTo(0, ref.current.offsetTop-110);  
  }
  var hash = window.location.hash.substring(1);
  useEffect(()=>{
     
      const interval = setInterval(() => {
        if(hash==="aboutus"){
          console.log('about')
          scrollToRef(focusDiv)
          
        }
        if(hash==="whypenguin"){
          console.log('Why')
          scrollToRefWhy(whyDiv)
        }
        clearInterval(interval)
      }, 500);
  },[whyDiv,focusDiv,hash])
  return (
    <>
    <Helmet>
      <meta name="description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"/>
      <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
      เพื่อให้ “ใครๆ ก็มีบ้านได้”" />
      <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
    </Helmet>
   <Header />
   <div className='container-fuild p-relative'>
        <img src='../about.jpg' className='w-100'/>
        <div id="aboutus"></div>
   </div>
    <div className='container'    ref={focusDiv} >
        <div className='row'>
            <div className='col-12 text-center '>
             <h1 className='my-4 p-relative d-inline-block'><img src="../penguin/penguin23.png" className="p-absolute penguin-23"/>ABOUT <span className='gold'>US</span></h1>
            </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 text-center">
            <p className='mb-4'><span className='color-primary me-1'>เพนกวินแอสเซทจาก เดอะ ไวท์สเปซ</span>ผู้พัฒนาอสังหาริมทรัพย์ประเภทบ้านมือสอง<br/>
            ที่มุ่งมั่นคัดสรรสินทรัพย์คุณภาพหลากหลายประเภท ทั้งบ้านเดี่ยว ทาวน์เฮ้าส์ ทาวน์โฮม คอนโด อาคารพาณิชย์ ในราคาที่คุ้มค่า </p>
            <p  id="whypenguin" >และเพราะบ้านเป็นมากกว่าที่อยู่อาศัย เราจึงเฟ้นหาสิ่งที่ตอบโจทย์จริงๆ ทั้งทำเล สิ่งอำนวยความสะดวก ความปลอดภัย<br/>
            การเดินทางที่สะดวก ที่สำคัญที่สุด เป็นราคาที่จับต้องได้แบบที่ใครๆ ก็มีบ้านกับเราได้
            </p>
          </div>
        </div>
        <div className="row mb-5"   ref={whyDiv}>
          <div className="col-12 col-sm-6 col-md-6">
          <img src='../TheWhiteSpace.jpg' className='w-100'/>
          </div>
          <div className="col-12 col-sm-6 col-md-6 ">
          <h1 className='my-4 lh-28  p-relative d-inline-block'>WHY<br/><span className='color-primary me-2'>PENGUIN</span>ASSET? <img src="../penguin/penguin9.png" className="p-absolute penguin-24"/></h1>
          <p className='mb-5'>
          <span className='color-primary me-1'>เพนกวินแอสเซทจาก เดอะ ไวท์สเปซ</span> คือ มืออาชีพในการบริหารอสังหาริมทรัพย์มือสอง
ที่มีความตั้งใจให้ทุกคนเป็นเจ้าของบ้านได้ เรามุ่งมั่นดำเนินงานเพื่อให้ลูกค้าได้บ้านมือสองที่มีคุณภาพดี
ในราคาที่เหมาะสม ด้วยทางเลือกและรูปแบบที่หลากหลายไม่ว่าเป็นซื้อ-เช่า-เช่าซื้อ รวมถึงให้บริการ
และช่วยเหลือลูกค้าจนจบกระบวนการ
          </p>
          <p>
          เพื่อให้ <span className='gold'>"ใครๆ ก็มีบ้านได้"</span> Penguin Asset มีทีมงานและพันธมิตรทางธุรกิจที่มีประสบการณ์และ
ความเชี่ยวชาญรอบด้านไม่ว่าจะเป็นการพัฒนาอสังหาริมทรัพย์หรือการให้คำปรึกษาด้านสินเชื่อ
ที่พร้อมให้บริการลูกค้าด้วยความมุ่งมั่นและตั้งใจเพื่อตอบโจทย์เรื่องบ้านมือสองของลูกค้า
          </p>
          <h4 className='text-center my-5' >เรื่องบ้านมือสองไว้ใจ <span className='color-primary  '>Penguin Asset</span></h4>
          </div>
        </div>
    </div>
    <Footer/>
    
    </>
  );
}

export default Aboutus;
