import { useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import '../index.css';
import Header from '../components/Header';
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../components/Footer';
import axios from "axios"
import { Button, Modal } from "react-bootstrap";
import {Helmet} from "react-helmet";
import {ValidateEmail} from "../services/Formatter";

function Forgotpassword() {
    const [loginInput, setLoginInput] = useState({email:""});
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [EmailErrorMessage, setEmailErrorMessage] = useState(false);
    const [sending, setSending] = useState(false);
    const [phoneErr, setPhoneErr] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [emailLoginErr, setEmailLoginErr] = useState("");

    const setloginValue=(e:any)=>{
        const {value,name} = e.target
        setLoginInput(oldValues=>({...oldValues,[name]:value}))
        if(name==="email"){
            const erremail = ValidateEmail(value);
            setEmailLoginErr(erremail);
          }
    }

 
    const onLogForgot = async () => {
            if(loginInput.email.trim() !==""){
                setSending(true)
                try {
                    const response:any = await axios.post(
                    `https://admin.penguinasset.com/api/auth/forgot`,loginInput
                    );
                    console.log(response.data);
                    setSuccess(true)
                    setSending(false)
                    setLoginInput({email:""})
                    setError(false);
                } catch (err:any) {
                    setError(true);
                    setSending(false)
                } finally {
                    setLoading(false);
                    setSending(false)
                }
            }else{
            alert("กรุณากรอกข้อมูลให้ถูกต้อง!!")
            setSending(false)
            }
    };

    useEffect(() => {
        
    }, [])
  return (
    <>
    <Helmet>
      <meta name="description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"/>
      <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
      เพื่อให้ “ใครๆ ก็มีบ้านได้”" />
      <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
    </Helmet>
   <Header />
    <div className='container contactus-form h-100vh pt-5'>
        <div className="row mb-5">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 m-auto">
            <div className='login-box'>
            <h2 className='color-primary lh-28 mb-4'>ลืมรหัสผ่าน</h2>
            <div className="form-group mb-2">
                    {(error)?
                            <div className="text-danger text-warning alert alert-danger p-1">เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง </div>
                            :<></>
                    }
                    {(success)?
                            <div className="text-success text-success alert alert-success p-1">Reset password link sent on your email. </div>
                            :<></>
                    }
                    <label htmlFor="lgemail"><span className='text-danger'>*</span> Email</label>
                    <input type="email" className="form-control penguinInput" id="lgemail" name="email" value={loginInput.email} aria-describedby="emailHelp" placeholder="" onChange={setloginValue}/>
                    <div className="text-danger text-warning">{(emailLoginErr!=="")? emailLoginErr:""}</div>
                </div>
                <div className='w-100 text-center mt-4'>
                    <button type="button" onClick={()=>onLogForgot()}  className="btn btn-penguin text-white w-100" >ตกลง</button>
                </div>
                </div>
          </div>
        </div>
    </div>
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>ส่งข้อมูลเรียบร้อย</Modal.Title>
        </Modal.Header>
        <Modal.Body>ขอบคุณที่สนใจสินค้าของเรา ทางเราจะรีบติดต่อกลับหาท่านโดยเร็ว</Modal.Body>
        <Modal.Footer>
          <Button variant="primary px-4" onClick={handleClose}>
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>
    <Footer/>
    {
      (sending)?
        <div className="overlay">
          <div className="custom-loader"></div>
        </div>
        :
        <></>
    }
    </>
  );
}

export default Forgotpassword;
