import React, { Component,useState} from 'react';
let accept :boolean =false
export default function Footer( ) {
    if(localStorage.getItem("accepted_cookes")){
        accept  =true
    }
    const [active, setActive] = useState(accept);
    const accepCooke=()=>{
        setActive(true)
        localStorage.setItem("accepted_cookes","true")
    }
    return (
        <>
        <footer className="container-fuild">
            <div className="row">
            <div className="col-12  col-sm-12 col-md-6 col-lg-3">
                
                <img src='../../logo.png' className='logo-footer'/>
                <p className='text-center mt-4'>
                ผู้พัฒนาอสังหาริมทรัพย์มือสอง ทั้งบ้านเดี่ยว ทาวน์เฮ้าส์ ทาวน์โฮม คอนโด อาคารพาณิชย์ ในราคาที่คุ้มค่า ทำเลดี เดินทางสะดวก 
ปลอดภัย มีสิ่งอำนวยความสะดวกครบถ้วน เราพร้อมให้บริการและช่วยเหลือลูกค้าจนจบกระบวนการ แบบที่ใครๆ ก็มีบ้านกับเราได้ 
                </p>
                <div className='text-center w-100'><img src='../../whitespace.png' className='whitespace-logo'/></div>
            </div>
            <div className="col-12  col-sm-12 col-md-6 col-lg-3 px-5">
                <div className='addr'>
                    <img src='../../icon/pin.png'/>
                    <p>
                    บริษัท เดอะ ไวท์สเปซ จำกัด<br/>
                    เลขที่ 87 โครงการเดอะแจส<br/>
                    รามอินทรา ห้อง A302 ชั้น 3<br/>
                    ถนนลาดปลาเค้า แขวงอนุสาวรีย์<br/> เขตบางเขน กรุงเทพฯ 10220

                    </p>
                </div>
                <div className='phone'>
                    <img src='../../icon/phone.png'/>
                    <p>
                    Call Center : <a href="tel:021147989">02 114 7989</a><br/>
                    Sales : <a href="tel:0977578888">097 757 8888</a><br/>
                    </p>
                </div>
                <div className='mail'>
                    <img src='../../icon/mail.png'/>
                    <p>
                    penguinasset@whitespace.co.th
                    </p>
                </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 f-menu">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2">
                        <a href="/">หน้าแรก</a>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2">
                        <a href="/assetsstore">สินทรัพย์</a>
                        <ul>
                            <li><a href="../type/บ้านเดี่ยว">บ้านเดี่ยว</a></li>
                            <li><a href="../type/ทาวน์เฮ้าส์">ทาวน์เฮ้าส์</a></li>
                            <li><a href="../type/คอนโด">คอนโดมิเนียม</a></li>
                            <li><a href="../type/อาคารพาณิชย์">อาคารพาณิชย์</a></li>
                        </ul>
                    </div>
                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-2">
                        <a href="/ourworks">นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>
                    </div> */}
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <a href="/aboutus">รู้จักเพนกวินแอสเซท</a>
                        <ul>
                            <li><a href="/aboutus#aboutus">Penguin Asset คือใคร?</a></li>
                            <li><a href="/aboutus#whypenguin">ทำไมต้องเลือกเรา</a></li>
                            <li><a href="/privacy-policy">นโยบายคุ้มครองข้อมูลส่วนบุคคล</a></li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 text-left">
                        <a href="/blogs">บทความ</a>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2">
                        <a href="/contactus">ติดต่อเรา</a>
                    </div>
                </div>
            </div>
            </div>
        </footer>
        <div className='contact-widget sale-icon'>
            <a href="/consignment" ><label>ฝากขาย<br/><span>บ้าน คอนโด</span><br/>ติดต่อ</label><img src='../../saleicon.png'/></a>
        </div>
        <ul className='followus'>
            <li><div>Follow Us</div></li>
            {/* <li><a href="#"><img src='../../icon/tw.png'/></a></li> */}
            <li><a href="https://www.facebook.com/PenguinAsset/" target="_blank"><img src='../../icon/fb.png'/></a></li>
            <li><a href="https://lin.ee/oK9IApV" target="_blank"><img src='../../icon/line.png'/></a></li>
            <li><a href="https://www.tiktok.com/@penguinasset.official?_t=8Ve0YkVJvbG&_r=1"><img src='../../icon/tiktok.png'/></a></li>
            <li><a href="https://instagram.com/penguinasset.official?igshid=YmMyMTA2M2Y="><img src='../../icon/ig.png'/></a></li>
        </ul>
        {
            (!active)?
            <div className='notie_consent'>
                <p className='text-dark'>
                เว็บไซต์ร้านของเราใช้คุกกี้ (cookie) เพื่อพัฒนาบริการและประสบการณ์การใช้งานของคุณ เพื่อถือว่าคุณยอมรับ <a href="/privacy-policy" className='text-white'>นโยบายคุกกี้และนโยบายข้อมูลส่วนบุคคล</a> ของเรากรุณากดปุ่มยอมรับ <button className='btn-accept' onClick={()=>accepCooke()}>ยอมรับ</button>
                </p>
            </div>
            :
            <></>
        }
        
        </>
    );
  }
