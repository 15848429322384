import { useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import '../index.css';
import Header from '../components/Header';
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../components/Footer';
import axios from "axios"
import { Button, Modal } from "react-bootstrap";
import {Helmet} from "react-helmet";
import { ValidatePhoneNumber,ValidateEmail} from "../services/Formatter";
const responseGoogle = (response:any) => {
    console.log(response);
  }  
function Singin() {
    const [formInput, setFormInput] = useState({email:"",mobile:"",password:"",repassword:"",consent:""});
    const [consent, setConsent] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showErrorPasswordMessage, setShowPasswordErrorMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [EmailErrorMessage, setEmailErrorMessage] = useState(false);
    const [sending, setSending] = useState(false);
    const [phoneErr, setPhoneErr] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const setInputvalue=(e:any)=>{
        const {value,name} = e.target
        setFormInput(oldValues=>({...oldValues,[name]:value}))
        if(name==="mobile"){
            const errphone = ValidatePhoneNumber(value);
            setPhoneErr(errphone);
          }
          if(name==="email"){
            const erremail = ValidateEmail(value);
            setEmailErr(erremail);
          }
      }
      const setConsentvalue=(e:any)=>{
        if(e.target.checked){
          setConsent(true)
        }else{
          setConsent(false)
        }
        
      }
   
    const submitForm = async () => {
        emailExits();
        if(consent){
            if(formInput.email.trim() !==""&&formInput.mobile.trim() !==""&&formInput.password.trim() !==""&&showErrorMessage!==true&&EmailErrorMessage!==true&&phoneErr===""&&emailErr ===""){
                try {
                    const response = await axios.post(
                    `https://admin.penguinasset.com/api/auth/register`,formInput
                    );
                    autoLogIn(formInput.email,formInput.password)
                    setFormInput({email:"",mobile:"",password:"",repassword:"",consent:""});
                    //setShow(!show)
                    
                } catch (err:any) {
                    setError(err.message);
                
                } finally {
                    setLoading(false);
                }
            }else{
            alert("กรุณากรอกข้อมูลให้ถูกต้อง!!")
            }
        }else{
            alert("คุณยังไม่ได้กดยอมรับเงื่อนไขและข้อตกลง!!")
        }
    };
    
    const autoLogIn = async (email:string,pass:string) => {

       
            try {
                const response:any = await axios.post(
                `https://admin.penguinasset.com/api/auth/login`,{email:email,password:pass}
                );
                sessionStorage.setItem("profile",JSON.stringify(response.data));
                window.location.href="/"
            } catch (err:any) {
                setError(err.message);
            
            } finally {
                setLoading(false);
            }
        
    };
    const emailExits = async () => {
        if(formInput.email.trim() !==""){
            setSending(true)
            try {
                const response = await axios.get(
                `https://admin.penguinasset.com/api/members/`+formInput.email
                );
                console.log(response.data);
                if(Object.keys(response.data).length>0){
                    setEmailErrorMessage(true)
                }else{
                    setEmailErrorMessage(false)
                }
                setSending(false)
            } catch (err:any) {
                setError(err.message);
                setSending(false)
            } finally {
                setLoading(false);
                setSending(false)
            }
        }
    };
      useEffect(() => {
        if (formInput.password !=="" && formInput.repassword!=="") {
            if (formInput.password !== formInput.repassword) {
                setShowErrorMessage(true);
            } else {
                setShowErrorMessage(false)
            }
        }
        if (formInput.password.length<8) {
            setShowPasswordErrorMessage(true);
        }else{
            setShowPasswordErrorMessage(false);
        }
    }, [formInput])
  return (
    <>
    <Helmet>
      <meta name="description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"/>
      <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
      เพื่อให้ “ใครๆ ก็มีบ้านได้”" />
      <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
    </Helmet>
   <Header />
    <div className='container contactus-form h-100vh pt-5'>
        <div className="row mb-5">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 contact-form mb-4 m-auto">
            <h2 className='color-primary lh-28'>สมัครสมาชิก</h2>
            <div className='w-100 mb-4'>กรอกรายละเอียด <small id="emailHelp" className="float-right form-text text-muted">(<span className='text-danger'>*</span> แสดงข้อมูลที่จำเป็นต้องใช้)</small></div>
            <form >
                <div className="form-group mb-2">
                    <label htmlFor="email"><span className='text-danger'>*</span> Email</label>
                    <input type="email" className="form-control penguinInput" id="email" name='email' aria-describedby="emailHelp" placeholder="" onChange={setInputvalue}/>
                    {EmailErrorMessage ? <div className="text-danger"> Email address already exists. </div> : ''}
                    <div className="text-danger text-warning">{(emailErr!=="")? emailErr:""}</div>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="password"><span className='text-danger'>*</span> Password</label>
                    <input type="password" className="form-control penguinInput" id="password" name="password"  placeholder="" onChange={setInputvalue}/>
                    {showErrorPasswordMessage ? <div className="text-danger"> Password minimum 8 characters </div> : ''}
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="repassword"><span className='text-danger'>*</span> Confirm Password</label>
                    <input type="password" className="form-control penguinInput" id="repassword" name="repassword" placeholder="" onChange={setInputvalue}/>
                    {showErrorMessage ? <div className="text-danger"> Passwords did not match </div> : ''}
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="mobile"><span className='text-danger'>*</span> Mobile</label>
                    <input type="tel" className="form-control penguinInput" id="mobile" name="mobile" placeholder="" onChange={setInputvalue}/>
                    <div className="text-danger text-warning">{(phoneErr!=="")? phoneErr:""}</div>
                </div>
                <div className="mb-4 text-start mt-4 " >
                <input type="checkbox" className="form-check-input" name="consent" id="consent"  onChange={setConsentvalue} checked={(consent)?true:false}/>
                        <p className="form-check-label ms-2 policy-text d-inline">ข้าพเจ้าตกลงที่จะให้ เพนกวินแอสเซท ดำเนินการและเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าให้เฉพาะ บริษัทในเครือของ เดอะไวท์สเปช หรือคู่ค้าที่เกี่ยวข้องกับเทคโนโลยีที่สนใจที่ระบุไว้เพื่อประโยชน์ของข้าพเจ้า เช่น การให้ข้อมูลผลิตภัณฑ์และบริการ เป็นต้น <a href="/cookies-policy">นโยบายความเป็นส่วนตัว</a></p>
                </div>
                {/* <div className='w-100 text-center'>
                Or register with
                </div>
                <div className='w-100 text-center py-2'>
                    <a href='#'><img src='../fb-singin.png' alt=''/></a>
                    <label className='sepete'></label>
                    <GoogleLogin
                        clientId="738300316639-7q82p111hgfisae4jkh9ug9erslkqig7.apps.googleusercontent.com"
                        render={renderProps => (
                            <a onClick={renderProps.onClick} ><img src='../gg-singin.png' alt=''/></a>
                        )}
                        buttonText="Login"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={true}
                    />
                    
                </div> */}
                <div className='w-100 text-center mt-4'>
                    <button type="button" onClick={()=>submitForm()} className="btn btn-penguin text-white">สมัครสมาชิก</button>
                </div>
                <div className='w-100 text-center mt-5'>
                    เคยลงทะเบียนแล้ว? <a href="/login">เข้าสู่ระบบ</a>
                </div>
            </form>
          </div>
        </div>
    </div>
    {
      (sending)?
        <div className="overlay">
          <div className="custom-loader"></div>
        </div>
        :
        <></>
    }
    </>
  );
}

export default Singin;
