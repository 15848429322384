import { useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import '../index.css';
import Header from '../components/Header';
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../components/Footer';
import axios from "axios"
import { Button, Modal } from "react-bootstrap";
import {Helmet} from "react-helmet";
import { ValidateEmail} from "../services/Formatter";
function Login() {
    const [loginInput, setLoginInput] = useState({email:"",password:"",remember:false});
    const [consent, setConsent] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [EmailErrorMessage, setEmailErrorMessage] = useState(false);
    const [sending, setSending] = useState(false);
    const [phoneErr, setPhoneErr] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [emailLoginErr, setEmailLoginErr] = useState("");
    const [fail, setFail] = useState(false);
    const setloginValue=(e:any)=>{
        const {value,name} = e.target
        setLoginInput(oldValues=>({...oldValues,[name]:value}))
        if(name==="email"){
            const erremail = ValidateEmail(value);
            setEmailLoginErr(erremail);
          }
    }
    const onLogIn = async () => {

            if(loginInput.email.trim() !==""&&loginInput.password.trim() !==""){
                setSending(true)
                try {
                    const response:any = await axios.post(
                    `https://admin.penguinasset.com/api/auth/login`,loginInput
                    );
                    sessionStorage.setItem("profile",JSON.stringify(response.data));
                    window.location.href="/"
                    setSending(false)
                } catch (err:any) {
                    setError(err.message);
                    setSending(false)
                    setFail(true)
                } finally {
                    setLoading(false);
                    setSending(false)
                }
            }else{
            alert("กรุณากรอกข้อมูลให้ถูกต้อง!!")
            setSending(false)
            }
    };
    useEffect(() => {
        
    }, [])
  return (
    <>
    <Helmet>
      <meta name="description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"/>
      <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า เพื่อให้ “ใครๆ ก็มีบ้านได้”" />
      <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
    </Helmet>
   <Header />
    <div className='container contactus-form h-100vh pt-5'>
        <div className="row mb-5">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 m-auto">
            <div className='login-box'>
                <h2 className='color-primary lh-28 mb-4'>ลงชื่อเข้าใช้</h2>
                {
                  (fail)?
                    <div className="alert alert-danger p-2" role="alert">
                      เกิดข้อผิดพลาดอีเมล์หรือรหัสผ่านไม่ถูกต้อง
                    </div>
                  :
                  <></>
                }
                
                <div className="form-group mb-2">
                        <label htmlFor="lgemail"><span className='text-danger'>*</span> Email</label>
                        <input type="email" className="form-control penguinInput" id="lgemail" name="email" aria-describedby="emailHelp" placeholder="" onChange={setloginValue}/>
                        <div className="text-danger text-warning">{(emailLoginErr!=="")? emailLoginErr:""}</div>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="lgpassword"><span className='text-danger'>*</span> Password</label>
                    <input type="password" className="form-control penguinInput" id="lgpassword" name="password" placeholder="" onChange={setloginValue}/>
                </div>
                <div className='row  py-2 mb-4'>
                    <div className='col-12 col-sm-6 col-md-6 text-left'>
                        <input type="checkbox" className="form-check-input" name="remember" id="remember" />
                        <label className="form-check-label ms-2 policy-text" htmlFor="remember">Remember me</label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-6 text-end'>
                    <a href='/forgotpassword'>Forgot Password ?</a>
                    </div>
                </div>
                <div className='w-100 text-center mt-4'>
                    <button type="button" onClick={()=>onLogIn()} className="btn btn-penguin text-white w-100">ลงชื่อเข้าใช้</button>
                </div>
                <div className='w-100 text-center mt-5'>
                    ยังไม่เคยลงทะเบียน? <a href="/signin">ลงทะเบียน</a>
                </div>
            </div>
          </div>
        </div>
    </div>
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>ส่งข้อมูลเรียบร้อย</Modal.Title>
        </Modal.Header>
        <Modal.Body>ขอบคุณที่สนใจสินค้าของเรา ทางเราจะรีบติดต่อกลับหาท่านโดยเร็ว</Modal.Body>
        <Modal.Footer>
          <Button variant="primary px-4" onClick={handleClose}>
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>
    <Footer/>
    {
      (sending)?
        <div className="overlay">
          <div className="custom-loader"></div>
        </div>
        :
        <></>
    }
    </>
  );
}

export default Login;
