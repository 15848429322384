import {useEffect, useState} from "react";
import "../index.css";
import Header from "../components/Header";
import "../css/font.css";
import "../scss/penguin.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Dropdown, DropdownButton, Modal} from "react-bootstrap";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";
import "../css/cal-interest.css";
import {useLocation} from "react-router-dom";
import {FacebookIcon, FacebookShareButton, LineIcon, LineShareButton,} from "react-share";

function Calculate_house_interest() {
    const [formInput, setFormInput] = useState({
        period: "",
        interestRate: 0,
        loanAmount: "",
    });
    const [firstRun, setFirstRun] = useState(true);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const pInterestRate = urlParams.get('pInterestRate');
    const pPeriod = urlParams.get('pPeriod');
    const pLoanAmount = urlParams.get('pLoanAmount');
    const pSelectedBank = urlParams.get('bid');

    const [maxLoanAmount, setMaxLoanAmount] = useState("");
    const [monthlyPay, setMonthlyPay] = useState("");
    const [minIncome, setMinIncome] = useState("");
    const [interestRate, setInterestRate] = useState("");
    const [shareShow, setShareShow] = useState(false);
    const [shareUrl, setShareUrl] = useState("");
    const [selectedBank, setSelectedBank] = useState(0);
    const notEmpty = (val: any) => {
        return val ? val : "0";
    };
    const renderDropdownItem = (
        img: string,
        bankName: string,
        interest: number,
        hideInterest: boolean
    ) => {
        return (
            <>
                <div className="row">
                    {img == "" ? (
                        <></>
                    ) : (
                        <div className="col col-1">
                            <img src={img} height="25px" alt={bankName}/>
                        </div>
                    )}
                    <div className="col">{bankName}</div>
                    {hideInterest ?(<></>) : (
                        <div className="col text-end">{interest}%</div>
                    )}
                </div>
            </>
        );
    };

    const [bankText, setBankText] = useState(
        renderDropdownItem("", "เลือกธนาคาร", 0, true)
    );

    const selectedItems = (img: string, bankName: string, interest: number, selectedBank: number) => {
        setFormInput((oldValues) => ({...oldValues, ["interestRate"]: interest}));
        setBankText(renderDropdownItem(img, bankName, interest, true));
        setSelectedBank(selectedBank);
    };

    const setInputValue = (e: any) => {
        const {value, name} = e.target;
        setFormInput((oldValues) => ({...oldValues, [name]: value}));
    };
    const banks = [
        {
            id: 0,
            img: "",
            name: "เลือกธนาคาร",
            rate: 0,
        },
        {
            id: 1,
            img: "./bank-icon/scb.png",
            name: "ธนาคารไทยพาณิชย์",
            rate: 7.3,
        },
        {
            id: 2,
            img: "./bank-icon/kbank.png",
            name: "ธนาคารกสิกรไทย",
            rate: 7.3,
        },
        {
            id: 3,
            img: "./bank-icon/bb.png",
            name: "ธนาคารกรุงเทพ",
            rate: 7.3,
        },
        {
            id: 4,
            img: "./bank-icon/kt.png",
            name: "ธนาคารกรุงไทย",
            rate: 7.57,
        },
        {
            id: 5,
            img: "./bank-icon/krungsri.png",
            name: "ธนาคารกรุงศรีอยุธยา",
            rate: 7.4,
        },
        {
            id: 6,
            img: "./bank-icon/kkp.png",
            name: "ธนาคารเกียรตินาคินภัทร",
            rate: 8.2,
        },
        {
            id: 7,
            img: "./bank-icon/cimb.png",
            name: "ธนาคารซีไอเอ็มบีไทย",
            rate: 9.25,
        },
        {
            id: 8,
            img: "./bank-icon/ttb.png",
            name: "ธนาคารทหารไทยธนชาต",
            rate: 7.83,
        },
        {
            id: 9,
            img: "./bank-icon/tcb.png",
            name: "ธนาคารไทยเครดิตเพื่อรายย่อย",
            rate: 9.25,
        },
        {
            id: 10,
            img: "./bank-icon/uob.png",
            name: "ธนาคารยูโอบี",
            rate: 8.25,
        },
        {
            id: 11,
            img: "./bank-icon/asb.png",
            name: "ธนาคารออมสิน",
            rate: 6.99,
        },
        {
            id: 12,
            img: "./bank-icon/ghbank.png",
            name: "ธนาคารอาคารสงเคราะห์",
            rate: 6.90,
        },
    ];
    const currency = (number: string, dec: number) => {
        return !parseFloat(number)
            ? "0.00"
            : parseFloat(number)
                .toFixed(dec)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    };
    const calculateLoan = () => {
        if (!parseFloat(formInput.loanAmount) || !parseInt(formInput.period))
            return;

        let loanAmount = parseFloat(formInput.loanAmount);
        let period = parseInt(formInput.period);
        let periodMonth = period * 12;
        let interestRate = formInput.interestRate ? formInput.interestRate : 0.0;

        let interestPerMonth = interestRate / 100 / 12;
        let ratePerMonth = Math.pow((1 + interestPerMonth), periodMonth);
        let monthlyPay = Math.ceil(loanAmount * (ratePerMonth / ((ratePerMonth - 1) / interestPerMonth)));
        let minPay = monthlyPay / 0.4;

        setMonthlyPay(monthlyPay.toString());
        setMaxLoanAmount(loanAmount.toString());
        setMinIncome(minPay.toString());
        setInterestRate(interestRate.toString());
    };
    const initState = () => {
        if (
            !pInterestRate ||
            !parseFloat(pInterestRate) ||
            !pPeriod ||
            !parseInt(pPeriod) ||
            !pLoanAmount ||
            !parseFloat(pLoanAmount)
        ) {
            return;
        }
        let bank = banks[parseInt(notEmpty(pSelectedBank))];
        selectedItems(bank.img, bank.name, bank.rate, bank.id);

        setFormInput({
            period: pPeriod?.toString() ?? "",
            interestRate: parseFloat(pInterestRate?.toString() ?? "0"),
            loanAmount: pLoanAmount?.toString() ?? "",
        });
    }

    useEffect(() => {
        initState()
        setFirstRun(false)
    }, []);

    useEffect(() => {
        calculateLoan()
    }, [firstRun]);

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"
                />
                <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี"/>
                <meta property="og:type" content="website"/>
                <meta
                    property="og:description"
                    content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
      เพื่อให้ “ใครๆ ก็มีบ้านได้”"
                />
                <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
            </Helmet>
            <Header/>
            <div className="container">
                <div className="text-center pd-top2">
                    <p className="h1 text-size-header2  ">
                        โปรแกรมคำนวณผ่อนบ้าน คำนวณเงินกู้ คำนวณสินเชื่อบ้านเบื้องต้น
                    </p>
                    <p className="text-color02">
                        การคำนวณเงินกู้นี้เป็นการประมาณการยอดเงินกู้ได้สูงสุด
                        เพื่อแนะนำโครงการ หรือ
                        บริการสินเชื่อที่เหมาะกับคุณซึ่งขึ้นอยู่กับรายได้และระยะในการผ่อนของคุณ
                    </p>
                </div>
                <div className="row my-2">
                    <div className="col-12 col-md-6 my-2">
                        <div className="card h-100 shadow pg-conner">
                            <div className="card-header  text-center">
                                <p className="mb-0 h1 text-size-header">คำนวณเงินกู้</p>
                            </div>
                            <div className="card-body">
                                <div className="form-group mb-2">
                                    <label htmlFor="loanAmount">
                                        <span className="text-danger">*</span> วงเงินที่ต้องการกู้
                                    </label>
                                    <div className="form-group input-group mb-3">
                                        <input
                                            onChange={setInputValue}
                                            value={formInput.loanAmount}
                                            type="text"
                                            className="form-control penguinInput pg-input "
                                            id="loanAmount"
                                            name="loanAmount"
                                            aria-describedby="emailHelp"
                                            placeholder="ใส่จำนวนเงิน"
                                        />
                                        <span className="input-group-text custom-btn">บาท</span>
                                    </div>
                                </div>
                                <div className="form-group mb-2">
                                    <label htmlFor="period">
                                        <span className="text-danger">*</span>{" "}
                                        ระยะเวลาที่ต้องการผ่อนชำระ (ไม่ควรเกิน30ปี)
                                    </label>
                                    <div className="form-group input-group mb-3">
                                        <input
                                            onChange={setInputValue}
                                            value={formInput.period}
                                            type="text"
                                            className="form-control penguinInput pg-input"
                                            id="period"
                                            name="period"
                                            aria-describedby="emailHelp"
                                            placeholder="ระยะเวลาที่ต้องการผ่อนชำระ"
                                        />
                                        <span className="input-group-text custom-btn">ปี</span>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-lg-8 mb-3">
                                        <label htmlFor="dropdown-item-button">
                                            เลือกธนาคาร
                                        </label>
                                        <DropdownButton
                                            id="dropdown-item-button"
                                            title={<>{bankText}</>}
                                            className="ddl-penguin"
                                        >
                                            {banks.map((b) => {
                                                return (
                                                    <>
                                                        <Dropdown.Item
                                                            as="button"
                                                            onClick={() =>
                                                                selectedItems(b.img, b.name, b.rate, b.id)
                                                            }
                                                        >
                                                            {renderDropdownItem(b.img, b.name, b.rate, false)}
                                                        </Dropdown.Item>
                                                    </>
                                                );
                                            })}
                                        </DropdownButton>
                                    </div>
                                    <div className="col">
                                        <div className="form-group mb-2">
                                            <label htmlFor="interestRate">
                                                <span className="text-danger">*</span>{" "}
                                                แก้ไขดอกเบี้ย
                                            </label>
                                            <div className="form-group mb-2 input-group mb-3">
                                                <input
                                                    type="number"
                                                    className="form-control penguinInput pg-input"
                                                    id="interestRate"
                                                    name="interestRate"
                                                    value={formInput.interestRate}
                                                    onChange={setInputValue}
                                                    placeholder="ใส่จำนวนเงิน"
                                                />
                                                <span className="input-group-text custom-btn">%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-color02">
                                    อัพเดทอัตราดอกเบี้ยเมื่อวันที่ 24  ต.ค. 2566
                                </p>
                                <div className="text-center pd-top2">
                                    <button
                                        className="btn-penguin text-color01"
                                        type="button"
                                        onClick={calculateLoan}
                                    >
                                        คำนวณเงินกู้
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col my-2">
                        <div className="card h-100 shadow pg-conner">
                            <div className="card-header  text-center">
                                <p className="mb-0 h1 text-size-header">ผลการคำนวณเงินกู้</p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-lg-4 col-interest-box1">
                                        <p className="h4">วงเงินกู้สูงสุด</p>
                                    </div>
                                    <div className="col-10 col-lg-6 text-end">
                                        <p className="h1 text-size-data1">
                                            {currency(maxLoanAmount, 2)}
                                        </p>
                                    </div>
                                    <div className="col-2 col-lg-2">
                                        <p className="h4 text-pd-15px">บาท</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-4 col-interest-box1">
                                        <p className="h4">ดอกเบี้ย</p>
                                    </div>
                                    <div className="col-10 col-lg-6 text-end">
                                        <p className="h1 text-size-data1">
                                            {currency(interestRate, 2)}
                                        </p>
                                    </div>
                                    <div className="col-2 col-lg-2">
                                        <p className="h4 text-pd-15px">%</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-4 col-interest-box1">
                                        <p className="h4">ยอดผ่อนชำระต่อเดือน</p>
                                    </div>
                                    <div className="col-10 col-lg-6 text-end">
                                        <p className="h1 text-size-data1">
                                            {currency(monthlyPay, 2)}
                                        </p>
                                    </div>
                                    <div className="col-2 col-lg-2">
                                        <p className="h4 text-pd-15px">บาท</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-4 col-interest-box1">
                                        <p className="h4">รายได้รวมขั้นต่ำของผู้กู้ร่วม</p>
                                    </div>
                                    <div className="col-10 col-lg-6 text-end">
                                        <p className="h1 text-size-data1">
                                            {currency(minIncome, 2)}
                                        </p>
                                    </div>
                                    <div className="col-2 col-lg-2">
                                        <p className="h4 text-pd-15px">บาท</p>
                                    </div>
                                </div>
                                <p className="h3 text-center ">
                                    เอกสารกู้ซื้อบ้าน ก่อนยื่นกู้ซื้อบ้าน ควรเตรียมอะไรบ้าง
                                </p>
                                <div className="text-center pd-top1">
                                    <a
                                        href="https://docs.google.com/forms/d/e/1FAIpQLSfp_wqjdv7r7LBYuJWj8JVQicx5P2uHK0uGAlnA-zI1qR_6Sw/viewform?usp=sf_link"
                                        className="btn btn-penguin text-color01"
                                        target="_blank"
                                    >
                                        เตรียมเอกสาร
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center pd-bottom01">
                    <a
                        className="cursor-pointer btn btn-penguin text-color01"
                        onClick={() => {
                            setShareUrl(
                                `https://penguinasset.com/Calculate_house_interest?pLoanAmount=${notEmpty(
                                    formInput.loanAmount
                                )}&pInterestRate=${notEmpty(formInput.interestRate)}&pPeriod=${notEmpty(
                                    formInput.period
                                )}&bid=${selectedBank}`
                            );
                            setShareShow(true);
                        }}
                    >
                        {" "}
                        <span className="h3">shared</span>{" "}
                    </a>
                </div>
            </div>

            <Modal
                show={shareShow}
                onHide={() => {
                    setShareShow(false);
                }}
                centered
            >
                <Modal.Header closeButton className="py-1">
                    <Modal.Title>แชร์</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <FacebookShareButton
                        url={shareUrl}
                        quote="คำนวณสินเชื่อ"
                        className="mx-1"
                    >
                        <FacebookIcon size={32} round/>
                    </FacebookShareButton>

                    <LineShareButton
                        url={shareUrl}
                        title="คำนวณสินเชื่อ"
                        className="mx-1"
                    >
                        <LineIcon size={32} round/>
                    </LineShareButton>
                    {/* <PinterestShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name}`}
            media  ={`https://penguinasset.com${data.image!}`}
            description  ={data.excerpt}
            className="mx-1"
          >
            <PinterestIcon size={32} round />
          </PinterestShareButton> 
          <TwitterShareButton
            url={shareUrl}
            title="คำนวณสินเชื่อ"
            className="mx-1"
            onClick={()=>share("twitter")}
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>*/}
                </Modal.Body>
            </Modal>

            <Footer/>
        </>
    );
}

export default Calculate_house_interest;
