import React,{ useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import '../index.css';
import Header from '../components/Header';
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../components/Footer';
import BlogItem from '../components/BlogItem';
import axios from "axios";
import {useParams} from "react-router-dom";
import parse from 'html-react-parser'
import {Helmet} from "react-helmet";
interface blogdata {
    id?:string,
    name?:string,
    expert?:string,
    description?:string,
    image?:string,
    created_at?:string
}
function Blog() {
  const blogs : blogdata={}
  const { name } = useParams();
  const [data, setData] = useState(blogs);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `https://admin.penguinasset.com/api/blogs/`+name
        );
        setData(response.data);
        setError(null);
      } catch (err:any) {
        setError(err.message);
       
      } finally {
        setLoading(false);
      }
    };
    getData();
   
  }, []);
  return (
    <>
    <Helmet>
      <meta name="description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"/>
      <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
      เพื่อให้ “ใครๆ ก็มีบ้านได้”" />
      <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
    </Helmet>
   <Header />
    <div className='container mt-5 blogdetail'>
      <div className='row'>
        <div className='col-12  col-sm-12 col-md-12'>
        <h3 className='color-primary  mt-4'>{data.name}</h3>
            <img src={`https://admin.penguinasset.com${data.image}`} className='w-100'/>
        </div>
        <div className='col-12  col-sm-12 col-md-12'>
          
          <p className='my-4 pb-5'>
            {(data.description)?parse(data.description!):""}
          </p>
        </div>
      </div>
    </div>
    <Footer/>
    
    </>
  );
}

export default Blog;
