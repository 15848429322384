import React, { Component } from 'react';
export default function BlogItem(props:any) {
    const data = props.blogitem;
    return (
      <div className='blog-item w-100 mb-5'>
        <div className='blog-img'>
        <a href={`blog/${data.name}`}><img src={`https://admin.penguinasset.com${data.image}`} className='w-100'/></a>
        </div>
        <div className='blog-title'  ><a href={`blog/${data.name}`}>{data.name}</a></div>
        <div className='blog-short'>
        {data.expert.length > 120 ?
          `${data.expert.substring(0, 120)}...` : data.expert
        }</div>
        <div className='blog-read'><a href={`blog/${data.name}`}>อ่านต่อ</a></div>
      </div>
    );
  }