import React from 'react';
import ReactDOM from 'react-dom/client';
import '../index.css';
import Header from '../components/Header';
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from '../components/Banner';
import PropertyItem from '../components/PropertyItem';
import { DropdownButton,Dropdown,Button } from 'react-bootstrap';
import Footer from '../components/Footer';
import {Helmet} from "react-helmet";
function Policy() {
  return (
    <>
     <Header />
    <Helmet>
      <meta name="description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"/>
      <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
      เพื่อให้ “ใครๆ ก็มีบ้านได้”" />
      <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
    </Helmet>
    <div className='container mt-5'>
        <div className='row'>
            <div className='col-12 text-center'>
                <div className="text-left policy">
                      <div className='text-center w-100'><img src='../../white.png' className='black-logo' /></div>
                      <div className='text-center w-100 my-5'>
                        <a href="#" className='btn-black width-260 btn-black-active  mb-2'>นโยบายการคุ้มครองข้อมูลส่วนบุคคล<br/>(Privacy Policy)</a>
                        <a href="/policy-notiecs" className='btn-black width-260 mb-2'>ประกาศการคุ้มครองข้อมูลส่วนบุคคล<br/>(Privacy Notice)</a>
                        <a href="/cookies-policy" className='btn-black width-260 mb-2'>นโยบายคุกกี้<br/>(Privacy Cookies)</a>
                      </div>
                      <h3 className="text-center mt-5">นโยบายการคุ้มครองข้อมูลส่วนบุคคล ของ บริษัท เดอะ ไวท์สเปซ จำกัด</h3>
                      <h3 className="text-center mb-5">(The Whitespace Privacy Policy)</h3>
                      <h5>บริษัท เดอะ ไวท์สเปซ จำกัด (“บริษัท”) ตระหนักถึงความสำคัญของการปกป้องความเป็นส่วนตัวของข้อมูล 
                        บริษัทเคารพสิทธิความเป็นส่วนตัวและให้ความสำคัญต่อการคุ้มครองข้อมูลส่วนบุคคลที่เกี่ยวข้องหรือทำธุรกรรมกับบริษัท 
                        โดยเป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562  (“พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล”) บริษัทจึงได้จัดทำนโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ขึ้น
                        เพื่อให้มีหลักเกณฑ์ กลไก มาตรการกำกับดูแล และการบริหารจัดการข้อมูลส่วนบุคคลอย่างชัดเจนและเหมาะสม ดังต่อไปนี้</h5>
                      <h4>1. คำนิยาม</h4>
                      <ol>
                      <dl>1.1  “ข้อมูลส่วนบุคคล” หมายถึง ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ ไม่ว่าทางตรงหรือทางอ้อม แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ</dl>
                      <dl>1.2  “ข้อมูลส่วนบุคคลอ่อนไหว” หมายถึง ข้อมูลที่เป็นเรื่องส่วนบุคคลโดยแท้ของบุคคล แต่มีความละเอียดอ่อนและอาจสุ่มเสี่ยงในการเลือกปฏิบัติอย่างไม่เป็นธรรม
                        เช่น เชื้อชาติ เผ่าพันธุ์ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนาหรือปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ
                          ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ หรือข้อมูลอื่นใด ซึ่งกระทบต่อเจ้าของข้อมูลส่วนบุคคลในทำนองเดียวกันตามที่คณะกรรมการคุ้มครอง
                          ข้อมูลส่วนบุคคลประกาศกำหนด</dl>
                      <dl>1.3  “การประมวลผล” หมายถึง การดำเนินการเกี่ยวกับการเก็บรวบรวม ใช้ เปิดเผย การลบ หรือการทำลายข้อมูลส่วนบุคคล</dl>
                      <dl>1.4  “เจ้าของข้อมูลส่วนบุคคล” หมายถึง บุคคลธรรมดาซึ่งเป็นเจ้าของข้อมูลส่วนบุคคลที่ข้อมูลส่วนบุคคลสามารถระบุตัวตนของบุคคลนั้นได้ ไม่ว่าทางตรงหรือทางอ้อม</dl>
                      <dl>1.5  “ผู้ควบคุมข้อมูลส่วนบุคคล” หมายถึง บุคคลหรือนิติบุคคลซึ่งมีอำนาจหน้าที่ตัดสินใจเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล</dl>
                      <dl>1.6  “ผู้ประมวลผลข้อมูลส่วนบุคคล” หมายถึง บุคคลหรือนิติบุคคลซึ่งดำเนินการเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลตามคำสั่งหรือในนามของบริษัท 
                        ทั้งนี้ บุคคลหรือนิติบุคคล ซึ่งดำเนินการดังกล่าวไม่เป็นผู้ควบคุมข้อมูลส่วนบุคคล</dl>
                      </ol>
                      <h4>2. การเก็บรวบรวมข้อมูลส่วนบุคคล</h4>
                      <ol>
                      <dl>2.1  บริษัทจะดำเนินการเก็บรวมรวมข้อมูลส่วนบุคคล โดยมี วัตถุประสงค์ ขอบเขต และใช้วิธีการที่ชอบด้วยกฎหมายและเป็นธรรม 
                        โดยในการเก็บรวบรวมนั้นจะทำเพียงเท่าที่จำเป็นแก่การดำเนินงานภายใต้วัตถุประสงค์ของบริษัทเท่านั้น</dl>
                      <dl>2.2  บริษัทอาจจัดเก็บและใช้ข้อมูลส่วนบุคคลหรือข้อมูลส่วนบุคคลอ่อนไหวซึ่งเกี่ยวกับความสนใจและบริการที่ใช้ ซึ่งอาจประกอบด้วย เชื้อชาติ ศาสนาหรือปรัชญา 
                        ข้อมูลสุขภาพ ข้อมูลชีวภาพ หรือข้อมูลอื่นใด ที่จะเป็นประโยชน์ในการดำเนินการหรือการให้บริการของบริษัท ทั้งนี้ การดำเนินการดังกล่าวข้างต้นจะเป็นไปตามที่กฎหมายบัญญัติไว้</dl>
                      <dl>2.3  บริษัทจะดำเนินการให้เจ้าของข้อมูล รับรู้ ให้ความยินยอมเป็นหนังสือหรือด้วยวิธีการทางอิเล็กทรอนิกส์หรือตามแบบวิธีการของบริษัทที่เหมาะสมและยอมรับได้
                        กรณีที่จัดเก็บข้อมูลส่วนบุคคลอ่อนไหวของเจ้าของข้อมูล บริษัทจะขอความยินยอมจากเจ้าของข้อมูลโดยชัดแจ้งก่อนทำการเก็บรวบรวม 
                        เว้นแต่การเก็บข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลอ่อนไหวจะเข้าข้อยกเว้นตามที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 หรือกฎหมายอื่นกำหนดไว้ </dl>
                      </ol>
                      <h4>3. วัตถุประสงค์ในการเก็บรวบรวมหรือใช้ข้อมูลส่วนบุคคล</h4>
                      <ol>
                      <dl>3.1  บริษัทจะดำเนินการเก็บรวมรวมหรือใช้ข้อมูลส่วนบุคคลของเจ้าของข้อมูลเพื่อประโยชน์ในการดำเนินงานของบริษัท หรือเพื่อให้บริการหรือดำเนินการตามสัญญา 
                      หรือเพื่อปรับปรุงคุณภาพในการปฏิบัติงานให้มีประสิทธิภาพมากยิ่งขึ้น หรือเพื่อประโยชน์ในการวิเคราะห์และนำเสนอบริการหรือผลิตภัณฑ์ใดๆ 
                      หรือเพื่อตรวจสอบการดำเนินการที่เกี่ยวข้องในกรณีที่มีการกระทำผิดเงื่อนไขการใช้บริการ หรือเปิดเผยข้อมูลส่วนบุคคลให้แก่บริษัทในกลุ่ม พันธมิตรทางธุรกิจ ผู้ให้บริการภายนอก 
                      ผู้ประมวลผลข้อมูล และบุคคลหรือนิติบุคคลใดๆ ที่มีสัญญาอยู่กับบริษัทหรือมีความสัมพันธ์ด้วย หรือเพื่อปฏิบัติตามกฎหมายหรือกฎระเบียบที่เกี่ยวข้องต่อการดำเนินงานของบริษัทหรือ
                      วัตถุประสงค์อื่นใดตามที่กฎหมายที่เกี่ยวข้องกำหนด โดยบริษัทจะจัดเก็บและใช้ข้อมูลดังกล่าวตามระยะเวลาเท่าที่จำเป็นตามวัตถุประสงค์ที่ได้แจ้งเจ้าของข้อมูลหรือตามที่กฎหมายกำหนด
                      ไว้เท่านั้น</dl>
                      <dl>3.2  บริษัทจะไม่กระทำการใดๆ แตกต่างจากที่ระบุในวัตถุประสงค์ของการเก็บรวบรวม เว้นแต่ได้แจ้งวัตถุประสงค์ใหม่ให้แก่เจ้าของข้อมูลทราบและได้รับความยินยอมจากเจ้าของข้อมูล 
                        หรือเป็นการปฏิบัติตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลหรือกฎหมายอื่นที่เกี่ยวข้อง</dl>
                      </ol>
                      <h4>4. การเปิดเผยข้อมูลส่วนบุคคล</h4>
                      <ol>
                      <dl>4.1 บริษัทจะไม่เปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลไปให้บุคคลใดโดยปราศจากความยินยอมและจะเปิดเผยตามวัตถุประสงค์ที่ได้มีการแจ้งไว้</dl>
                      <dl>4.2 บริษัทอาจมีความจำเป็นในการเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลให้แก่บริษัทในกลุ่มหรือบุคคลอื่นทั้งในและต่างประเทศเพื่อเป็นประโยชน์
                        ต่อการดำเนินงานของบริษัทและการให้บริการแก่เจ้าของข้อมูลโดยในการเปิดเผยข้อมูลส่วนบุคคลให้แก่บุคคลดังกล่าว บริษัทจะดำเนินการให้บุคคลเหล่านั้น
                        เก็บรักษาข้อมูลส่วนบุคคลไว้เป็นความลับและไม่นำไปใช้เพื่อวัตถุประสงค์อื่นนอกเหนือจากขอบเขตที่บริษัทได้กำหนดไว้</dl>
                      <dl>4.3 บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลภายใต้หลักเกณฑ์ที่กฎหมายกำหนด เช่น 
                        การเปิดเผยข้อมูลส่วนบุคคลต่อหน่วยงานราชการ หน่วยงานภาครัฐ หน่วยงานกำกับดูแล รวมถึงในกรณีที่มีการร้องขอให้เปิดเผยข้อมูลโดยอาศัยอำนาจตามกฎหมาย</dl>
                      <dl>4.4 หากมีการเปลี่ยนแปลงในธุรกิจของบริษัทในส่วนที่มีการจำหน่ายไป ได้มา หรือควบรวมกับบริษัทอื่น (แล้วแต่กรณี) 
                        อาจมีการใช้ข้อมูลของท่านในลักษณะเดียวกับที่อธิบายไว้ในประกาศการคุ้มครองข้อมูลส่วนบุคคลนี้ </dl>
                      </ol>
                      <h4>5. การรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล</h4>
                      <ol>
                      <dl>5.1 บริษัทจะจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลอย่างเหมาะสมและสอดคล้องกับกฎหมาย นโยบาย ระเบียบ ข้อกำหนด
                        และแนวปฏิบัติด้านการคุ้มครองข้อมูลส่วนบุคคลให้แก่พนักงานของบริษัทและบุคคลอื่นที่เกี่ยวข้อง</dl>
                      <dl>5.2 บริษัทสนับสนุนและส่งเสริมให้พนักงานมีความรู้และตระหนักถึงหน้าที่และความรับผิดชอบในการเก็บรวบรวม การจัดเก็บรักษา 
                        การใช้ และการเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลเพื่อให้บริษัทสามารถปฏิบัติตามนโยบายและกฎหมายคุ้มครองข้อมูลส่วนบุคคลได้อย่างถูกต้องและ มีประสิทธิภาพ</dl>
                      </ol>
                      <h4>6. สิทธิของเจ้าของข้อมูลส่วนบุคคล</h4>
                      <ol>
                      <dl>6.1 สิทธิในการถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่ได้ให้ความยินยอมไว้ (Right to withdraw consent)  
                        ทั้งนี้ การเพิกถอนความยินยอมไม่ส่งผลกระทบต่อการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่ได้ให้ความยินยอมไว้แล้ว สิทธิขอเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคลที่เกี่ยวกับตน 
                        หรือขอให้เปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลดังกล่าวที่ตนไม่ได้ให้ความยินยอม</dl>
                      <dl>6.2 สิทธิในการขอเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคล หรือขอให้เปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลดังกล่าวที่ตนไม่ได้ให้ความยินยอม (Right to access)</dl>
                      <dl>6.3 สิทธิในการขอให้โอนข้อมูลส่วนบุคคล (Right to data portability)</dl>
                      <dl>6.4 สิทธิในการคัดค้านการเก็บรวบรวม ใช้ เปิดเผย และประมวลผลข้อมูลส่วนบุคคลที่เกี่ยวกับตน (Right to object)</dl>
                      <dl>6.5 สิทธิในการขอให้ลบหรือทำลายข้อมูลส่วนบุคคลหรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคล (Right to erasure)</dl>
                      <dl>6.6 สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคล (Right to restriction of processing)</dl>
                      <dl>6.7 สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง (Right to rectification)</dl>
                      <dl>6.8 สิทธิในการร้องเรียน (Right to make a complaint)</dl>
                      </ol>
                      <h4>7. การทบทวนและเปลี่ยนแปลงนโยบายการคุ้มครองข้อมูลส่วนบุคคล</h4>
                      <ol>
                        <dl>บริษัทอาจทำการปรับปรุงหรือแก้ไขนโยบายฉบับนี้เป็นครั้งคราวเพื่อให้สอดคล้องกับข้อกำหนดตามกฎหมาย การเปลี่ยนแปลงการดำเนินงานของบริษัท 
                          รวมถึงข้อเสนอแนะและความคิดเห็นจากหน่วยงานต่างๆ โดยบริษัทจะประกาศแจ้งการเปลี่ยนแปลงให้ทราบอย่างชัดเจน </dl>
                      </ol>
                      <h4>8. เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</h4>
                      <ol>
                        <dl>บริษัทจะแต่งตั้งเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer : DPO) เพื่อตรวจสอบการดำเนินการของบริษัทที่เกี่ยวกับการประมวลผลข้อมูลส่วนบุคคล
                          รวมถึงกฎหมายที่เกี่ยวข้องกับการคุ้มครองข้อมูลส่วนบุคคล ตาม พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล และเพื่อให้การดำเนินงานตามแนวนโยบายเกี่ยวกับการคุ้มครองข้อมูลส่วน
                          บุคคลให้เป็นไปด้วยความเรียบร้อย</dl>
                      </ol>
                      <h4>9. ช่องทางติดต่อกับบริษัท</h4>
                      <ol>
                        <dl><p><strong>ฝ่ายกำกับดูแลการปฎิบัติตามกฎระเบียบ</strong></p>
                        <p><strong>บริษัท เดอะ ไวท์สเปซ จำกัด </strong></p>
                        <p>87 โครงการเดอะแจส รามอินทรา ชั้น 3 ห้อง A302 ถนนลาดปลาเค้า แขวงอนุสาวรีย์ เขตบางเขน กรุงเทพฯ  10220</p>
                        <p>โทรศัพท์: 66 (0) 2 114 7989 , 66 (065) 146 4159</p>
                        <p>Email: Nadduean@whitespace.co.th</p></dl>
                        
                      </ol>
                      <div className="clearfix mb-5"></div>
                      <h4 className="text-center">นโยบายการคุ้มครองข้อมูลส่วนบุคคลนี้ประกาศไว้เมื่อวันที่ 25 พฤษภาคม 2565</h4>
                      <h4 className="text-center">(แก้ไขล่าสุด ณ วันที่ 24 มิถุนายน 2565)</h4>
                    <div className="row mb-4">
                      <div className="col-md-12 text-end"><strong>(นายปกรณ์ พรรณเชษฐ์)</strong></div>
                      <div className="col-md-12 text-end"><strong>ประธานเจ้าหน้าที่บริหาร</strong></div>
                      <div className="col-md-12 text-end"><strong>บริษัท เดอะ ไวท์สเปซ จำกัด</strong></div>
                  </div>
                  </div>
            </div>
        </div>
    </div>
    <Footer/>
    
    </>
  );
}

export default Policy;
