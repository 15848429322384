import React, { useState,useEffect } from 'react';
import axios from "axios"
export default function Favorite(props:any) {
    const data = props.item;
    const [active, setActive] = useState(false);
    const itemId = data.id.toString()
    const handleLikeClick = async () => {
        let favoriteItem:any = null;
        if(sessionStorage.getItem('favoriteItem')!== null){
            favoriteItem = sessionStorage.getItem('favoriteItem');
        }
        let objItem:any = [];
        if(favoriteItem!==null){
            objItem = favoriteItem?.split(",");
        }
        if(!objItem.includes(itemId)){
            objItem.push(itemId)
            sessionStorage.setItem('favoriteItem',objItem);
            console.log("Add Favorite",objItem);
            setActive(true)
            const profile = sessionStorage.getItem('profile');
            if(profile){
                const userdata = JSON.parse(profile);
                try {
                    const response = await axios.post(
                    `https://admin.penguinasset.com/api/favorites`,{user_id:userdata.user.id,asset_id:itemId}
                    );
                    console.log(response)
                } catch (err:any) {
                
                } finally {
                    
                }
            }
        }else{
            const index = objItem.indexOf(itemId);
            if (index !== -1) {
                objItem.splice(index, 1);
                sessionStorage.setItem('favoriteItem',objItem);
                console.log("Remove Favorite",objItem);
                setActive(false)
                const profile = sessionStorage.getItem('profile');
                if(profile){
                    const userdata = JSON.parse(profile);
                    try {
                        const response = await axios.delete(
                        `https://admin.penguinasset.com/api/favorites/delete`,{data:{userid:userdata.user.id,assetid:itemId}}
                        );
                        console.log(response)
                    } catch (err:any) {
                    
                    } finally {
                        
                    }
                }
            }
        }

        
    }
    useEffect(() => {
        if(sessionStorage.getItem('favoriteItem')!== null){
            let favoriteItem = sessionStorage.getItem('favoriteItem');
            const at:any  = favoriteItem?.split(",");
            if(at.includes(itemId)){
                setActive(true)
            }
        } 
    },[active])
    return (
      <>
      {
      (active)?
        <img src='../../icon/like-r.png' className='like cursor-pointer' onClick={()=>handleLikeClick()} />
        :
        <img src='../../icon/like.png' className='like cursor-pointer' onClick={()=>handleLikeClick()}/>
      }
      </>
    );
  }