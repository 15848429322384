import { useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import '../index.css';
import Header from '../components/Header';
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../components/Footer';
import axios from "axios"
import { Button, Modal } from "react-bootstrap";
import {Helmet} from "react-helmet";
import { ValidatePhoneNumber,ValidateEmail} from "../services/Formatter";
function Register() {
    const [formInput, setFormInput] = useState({name:"",email:"",tel:"",line:"",asset:"",price:"",location:"",size:"",message:""});
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [phoneErr, setPhoneErr] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [sending, setSending] = useState(false);
    const [consent, setConsent] = useState(false);
    const setInputvalue=(e:any)=>{
        const {value,name} = e.target
        setFormInput(oldValues=>({...oldValues,[name]:value}))
        if(name==="tel"){
          const errphone = ValidatePhoneNumber(value);
          setPhoneErr(errphone);
        }
        if(name==="email"){
          const erremail = ValidateEmail(value);
          setEmailErr(erremail);
        }
    }
    const setConsentvalue=(e:any)=>{
      if(e.target.checked){
        setConsent(true)
      }else{
        setConsent(false)
      }
      
    }
    const submitForm = async () => {
      console.log(formInput)
        if(formInput.name.trim() !==""&&formInput.tel.trim() !==""&&formInput.asset.trim() !==""&&phoneErr===""&&emailErr ===""&&formInput.location.trim() !==""&&consent){
          setSending(true)
          try {
            const response = await axios.post(
              `https://admin.penguinasset.com/api/sales`,formInput
            );
            setFormInput({name:"",email:"",tel:"",line:"",asset:"",price:"",location:"",size:"",message:""});
            setShow(!show)
            setSending(false)
          } catch (err:any) {
            setLoading(false);
            setError(err.message);
          
          } finally {
            setLoading(false);
          }
        }else{
          setLoading(false);
          alert("กรุณากรอกข้อมูลให้ถูกต้อง!!")
        }
      };
  return (
    <>
    <Helmet>
      <meta name="description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"/>
      <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
      เพื่อให้ “ใครๆ ก็มีบ้านได้”" />
      <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
    </Helmet>
   <Header />
    <div className='container h-100vh pt-5'>
        <div className="row mb-5  contactus p-relative">
          <div className="col-12 col-sm-12 col-md-12 contact-form">
            <h2 className='color-primary lh-28'>ฝากขายกับเพนกวินแอสเซท</h2>
            <img src="../penguin/penguin10.png" className="p-absolute penguin-10"/>
            <div className="row">
                <div className="form-group mb-5 col-12 col-sm-6 col-md-6">
                    <div className='w-100'>กรอกรายละเอียด <small id="emailHelp" className="float-right form-text text-muted">(<span className='text-danger'>*</span> แสดงข้อมูลที่จำเป็นต้องใช้)</small></div>
                </div>
            </div>
            <form className='contactus-form'>
                <div className="row">
                    <div className="form-group mb-2 col-12 col-sm-6 col-md-6">
                        <label htmlFor="txt_name"><span className='text-danger'>*</span> ชื่่อ-นามสกุล</label>
                        <input type="text" className="form-control penguinInput" id="txt_name" name="name" placeholder="" required value={formInput.name} onChange={setInputvalue} />
                    </div>
                    <div className="form-group mb-2 col-12 col-sm-6 col-md-6">
                        <label htmlFor="txt_email"><span className='text-danger'>*</span>อีเมล์</label>
                        <input type="email" className="form-control penguinInput" id="txt_email" name="email" placeholder="" value={formInput.email} onChange={setInputvalue} />
                        <div className="text-danger text-warning">{(emailErr!=="")? emailErr:""}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group mb-2 col-12 col-sm-6 col-md-6">
                        <label htmlFor="txt_tel"><span className='text-danger'>*</span> เบอร์โทรศัพท์</label>
                        <input type="tel" className="form-control penguinInput" id="txt_tel" name="tel" placeholder="" required  value={formInput.tel} onChange={setInputvalue} />
                        <div className="text-danger text-warning">{(phoneErr!=="")? phoneErr:""}</div>
                    </div>
                    <div className="form-group mb-2 col-12 col-sm-6 col-md-6">
                        <label htmlFor="txt_line">Line ID</label>
                        <input type="text" className="form-control penguinInput" id="txt_line" name="line" placeholder=""  value={formInput.line} onChange={setInputvalue} />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group mb-4 col-12 col-sm-6 col-md-6">
                        <label htmlFor="ddl_asset"><span className='text-danger'>*</span> ประเภทสินทรัพย์</label>
                        <select className="form-control penguinInput penguinSelect" id='ddl_asset' name="asset" required  value={formInput.asset} onChange={setInputvalue} >
                            <option value="">-- ประเภทสินทรัพย์ --</option>
                            <option value="บ้านเดี่ยว">บ้านเดี่ยว</option>
                            <option value="ทาวน์เฮ้าส์">ทาวน์เฮ้าส์</option>
                            <option value="คอนโดมิเนียม">คอนโดมิเนียม</option>
                            <option value="อาคารพาณิชย์">อาคารพาณิชย์</option>
                            <option value="อื่นๆ">อื่นๆ</option>
                        </select>
                    </div>
                    <div className="form-group mb-4 col-12 col-sm-6 col-md-6">
                        <label htmlFor="txt_price">ราคา</label>
                        <input type="number" className="form-control penguinInput" id="txt_price" name="price" placeholder=""  value={formInput.price} onChange={setInputvalue} />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group mb-4 col-12 col-sm-6 col-md-6">
                        <label htmlFor="txt_location"><span className='text-danger'>*</span>ที่ตั้งทรัพย์สิน</label>
                        <input type="text" className="form-control penguinInput" id="txt_location" name="location" placeholder=""  value={formInput.location} onChange={setInputvalue} />
                    </div>
                    <div className="form-group mb-4 col-12 col-sm-6 col-md-6">
                        <label htmlFor="txt_size">ขนาดพื้นที่</label>
                        <input type="text" className="form-control penguinInput" id="txt_size" name="size" placeholder=""  value={formInput.size} onChange={setInputvalue} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="w-100">
                        <div className="form-group mb-4">
                        <label htmlFor="message">รายละเอียดเพิ่มเติม (สามารถใส่ได้ไม่เกิน 300 ตัวอักษร)</label>
                        <textarea onChange={setInputvalue} maxLength={300}   value={formInput.message} className="form-control penguinInput" id="message" name="message" rows={3}  />
                    </div>
                  </div>
                </div>
                <div className="row">
                    <div className="mb-4 text-left">
                      <input type="checkbox" className="form-check-input" name="consent" id="consent"  onChange={setConsentvalue} checked={(consent)?true:false}/>
                      <p className="form-check-label ms-2 policy-text d-inline text-left" >ข้าพเจ้าตกลงและยินยอมที่จะให้ บริษัท เดอะ ไวท์สเปช จำกัด เก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุลคลของข้าพเจ้า  ตามวัตถุประสงค์
      ที่ได้กำหนดไว้ในนโยบายและประกาศการคุ้มครองข้อมูลของบริษัท บริษัทจะดำเนินการเท่าที่จำเป็นเพื่อรักษาข้อมูลส่วนบุลคลของท่าน
      ตามกฎหมายคุ้มครองข้อมูลส่วนบุลคลที่เกี่ยวข้อง <a href="/policy-notiecs">นโยบายการคุ้มครองข้อมูลส่วนบุลคล</a></p>
                    </div>
                </div>
                <div className='w-100 text-center mt-4'>
                    <button type="button" onClick={()=>submitForm()} className="btn btn-penguin text-white">ส่งข้อความ</button>
                </div>
            </form>
          </div>
        </div>
    </div>
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>ส่งข้อมูลเรียบร้อย</Modal.Title>
        </Modal.Header>
        <Modal.Body>ขอบคุณที่สนใจสินค้าของเรา ทางเราจะรีบติดต่อกลับหาท่านโดยเร็ว</Modal.Body>
        <Modal.Footer>
          <Button variant="primary px-4" onClick={handleClose}>
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>
    <Footer/>
    {
      (sending)?
        <div className="overlay">
          <div className="custom-loader"></div>
        </div>
        :
        <></>
    }
    </>
  );
}

export default Register;
