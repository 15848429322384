import React,{ useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import '../index.css';
import Header from '../components/Header';
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../components/Footer';
import BlogItem from '../components/BlogItem';
import axios from "axios";
import {Helmet} from "react-helmet";
function Blog() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `https://admin.penguinasset.com/api/blogs`
        );
        setData(response.data);
        setError(null);
      } catch (err:any) {
        setError(err.message);
       
      } finally {
        setLoading(false);
      }
    };
    getData();
   
  }, []);
  return (
    <>
    <Helmet>
      <meta name="description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
        เพื่อให้ “ใครๆ ก็มีบ้านได้”"/>
      <meta property="og:title" content="เพนกวินแอสเซท บ้านมือสองทำเลดี" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="เพนกวินแอสเซท บ้านมือสองทำเลดี พร้อมให้บริการลูกค้าจนจบกระบวนการ ในราคาที่คุ้มค่า
      เพื่อให้ “ใครๆ ก็มีบ้านได้”" />
      <meta property="og:image" content="%PUBLIC_URL%/share.jpg"/>
    </Helmet>
   <Header />
    <div className='container mt-5'>
    {data &&data.filter((rl:any) => rl.highlight=== 1).map((item:any, index:number) => (
      <div className='row'>
        <div className='col-12  col-sm-12 col-md-6'>
        <a   href={`blog/${item.name}`}> <img src={`https://admin.penguinasset.com${item.image}`} className='w-100'/></a>
        </div>
        <div className='col-12  col-sm-12 col-md-6'>
          <h3 className='color-primary  mt-4'><a  className="red"  href={`blog/${item.name}`}>{item.name}</a></h3>
          <p className='my-4'>
          {item.expert.length > 250 ?
            `${item.expert.substring(0, 250)}...` : item.expert
          }
          </p>
          <a className='btn-penguin text-white' href={`blog/${item.name}`}>อ่านต่อ</a>
        </div>
      </div>
    ))}
        
    </div>
    <div className='container mt-5'>
        <div className="row mb-2">
        {data &&data.filter((rl:any) => rl.highlight!== 1).map((item:any, index:number) => (
          <div className="col-12 col-sm-6 col-md-3 mb-2">
          <BlogItem blogitem={item}/>
          </div>
        ))}
        </div>
        
    </div>
    <Footer/>
    
    </>
  );
}

export default Blog;
