import { useState, useEffect } from "react";
import {useParams} from "react-router-dom";
import '../index.css';
import Header from '../components/Header';
import '../css/font.css';
import '../scss/penguin.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { Button, Modal } from 'react-bootstrap';
import Footer from '../components/Footer';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import axios from "axios"
import parse from 'html-react-parser'
import Item from "../components/Item";
import NumberFormat from 'react-number-format';
import Helmet from 'react-helmet';
import  { HelmetProvider } from 'react-helmet-async';
import Favorite from "../components/Favorite"
import { ValidatePhoneNumber} from "../services/Formatter";
import Gallerys from "../components/Gallerys"
import {
    EmailIcon,
    FacebookIcon,
    TwitterIcon,
    LineIcon,
    EmailShareButton,
    FacebookShareButton,
    LineShareButton,
    TwitterShareButton,
  } from "react-share";
interface Assetdata {
    id?:string,
    code?:string,
    slug?:string,
    name?:string,
    deed?:string,
    excerpt?:string,
    desc?:string,
    tags?:string,
    address?:string,
    map?:string,
    contact_number?:string,
    announcement_type?:string,
    asset_type?:number,
    assettypes_name?:string,
    province?:string,
    provinces_name?:string,
    amphure?:string,
    district?:string,
    locality?:string,
    price?:number,
    sale_price?:number,
    land_area?:string,
    land_area_price?:string,
    usable_area?:string,
    usable_area_price?:string,
    width?:string,
    transfer_fee?:string,
    train?:string,
    ville?:string,
    turn_towards?:string,
    number_of_floors?:string,
    floor?:string,
    building?:string,
    bedroom?:string,
    bathroom?:string,
    kitchen?:string,
    car_park?:string,
    furniture?:string,
    air?:string,
    flooring_material?:string,
    viewed?:string,
    image?:string,
    highlight?:string,
    status?:string,
    created_by?:string,
    created_at?:string,
    updated_by?:string,
    updated_at?:string,
    announcementtypes_name?:string,
    relates?:string
}
interface Fac {
    assetstore_id?:string,
    facilitie_id?:string,
    name?:string,
    icon?:string,
    status?:string
}
function Asset() {
  const { code } = useParams();
  const ScreenWidth:number = 1320 - 33;
  const Ass : Assetdata={}
  const faci : Fac={}
  const [data, setData] = useState(Ass);
  const [facility, setFacility] = useState([faci]);
  const [relates, setRelates] = useState([]);
  const [tags, setTags] = useState("");
  const [gallerys, setGallerys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formInput, setFormInput] = useState({name:"",phone:"",message:"",asset_name:""});
  const [consent, setConsent] = useState(false);
  const [show, setShow] = useState(false);
  const [shareShow, setShareShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShareClose = () => setShareShow(false);
  const handleShareShow = () => setShareShow(true);

  const [phoneErr, setPhoneErr] = useState("");
  const [sending, setSending] = useState(false);
  const numberWithCommas=(x:any)=> {
    console.log("number=>",x);
    if(x!=="undefined"){
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
 }
  const setInputvalue=(e:any)=>{
    const {value,name} = e.target
    setFormInput(oldValues=>({...oldValues,[name]:value}))
    if(name==="phone"){
      const errphone = ValidatePhoneNumber(value);
      setPhoneErr(errphone);
    }
  }
  const setConsentvalue=(e:any)=>{
    if(e.target.checked){
      setConsent(true)
    }else{
      setConsent(false)
    }
    
  }
  const submitForm = async () => {
    if(formInput.name.trim() !==""&&formInput.phone.trim() !==""&&formInput.message.trim() !==""&&consent ===true&&phoneErr ===""){
      setSending(true)
      try {
        const response = await axios.post(
          `https://admin.penguinasset.com/api/message`,formInput
        );
        setFormInput({name:"",phone:"",message:"",asset_name:""})
        setConsent(false)
        setShow(!show)
        setSending(false)
      } catch (err:any) {
        setError(err.message);
        setSending(false)
      
      } finally {
        setLoading(false);
        setSending(false)
      }
    }else{
      alert("กรุณากรอกข้อมูลให้ถูกต้อง!!")
    }
  };
  const share = async(shareWith:any) =>{
    const profile = sessionStorage.getItem('profile');
    handleClose()
    if(profile){
      const userdata = JSON.parse(profile);
        try {
            const response = await axios.post(
            `https://admin.penguinasset.com/api/shares`,{user_id:userdata.user.id,share:shareWith,asset_id:data.id}
            );
            console.log(response)
            handleClose()
        } catch (err:any) {
          handleClose()
        } finally {
          handleClose()
        }
    }
  }
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `https://admin.penguinasset.com/api/assetstores/`+code
        );
        setData(response.data[0]);
        setTags(response.data[0].tags)
        setFormInput(oldValues=>({...oldValues,["asset_name"]:response.data[0].name}))
        //setGallerys(JSON.parse(response.data[0].gallerys))
        setError(null);
        getFacility(response.data[0].id)
        puView(response.data[0].id)
        getRelate(response.data[0].relates);
        const gg:any = [];
        const gal =  JSON.parse(response.data[0].gallerys).map((img:any,index:number)=>{
            const image = {
              original:"https://admin.penguinasset.com/uploads/assetstore/"+response.data[0].code+"/thumbnail_"+img,
              thumbnail:"https://admin.penguinasset.com/uploads/assetstore/"+response.data[0].code+"/thumbnail_"+img,
              loading : "lazy"
            }
            gg.push(image)
        })   
        setGallerys(gg)
       
      } catch (err:any) {
        setError(err.message);
       
      } finally {
        setLoading(false);
      }
    };
    
    const getFacility = async (id:string) => {
        try {
          const response = await axios.get(
            `https://admin.penguinasset.com/api/assetfacility/`+id
          );
          setFacility(response.data);
          
        } catch (err:any) {
          setError(err.message);
         
        } finally {
          setLoading(false);
        }
      };
      
      const getRelate = async (relate:string="") => {
        if(relate!==null){
          let str = ""
          const params  = await JSON.parse(relate).map((item:any,index:number)=>
                str = str+","+item.value
          )
          try {
            const response = await axios.get(
              `https://admin.penguinasset.com/api/relates`, {params:{relates:str}}
            );
            setRelates(response.data);
            setError(null);
          } catch (err:any) {
            setError(err.message);
          
          } finally {
            setLoading(false);
          }
        }
      };
      const puView = async (id:string) => {
        try {
          const response = await await axios.put(
            `https://admin.penguinasset.com/api/assetview/`+id
           );
          
          setError(null);
        } catch (err:any) {
          setError(err.message);
         
        } finally {
          setLoading(false);
        }
      };
    getData();
   
  }, []);;
  return (
    
      (data!==null) ?
      <HelmetProvider>
    
    <Helmet>
        <title>{data.name}</title>
        <meta name="description" content={data.excerpt} />
        <meta property="og:url" content={window.location.href}/>
        <meta property="og:image" content={`https://admin.penguinasset.com${data.image!}`}/>
        <meta property="og:title" content={data.name}/>
        <meta property="og:description" content={data.excerpt}/>
    </Helmet>  
   <Header />
   <div className="container">
   <div className='breadcrumb mw-100'>
        <ul>
            <li><a href={`/`} className="red-color">หน้าหลัก</a></li>
            <li><a href={`/type/${data.assettypes_name}`} className="red-color">{data.assettypes_name}</a></li>
            <li><a href={`/type/${data.assettypes_name}?province=${data.provinces_name}`} className="red-color">{data.provinces_name}</a></li>
            <li>รหัสสินทรัพย์ {data.code}</li>
        </ul>
    </div>
    <div className='asset-header mw-100'>
        <h1>{data.name}</h1>
        <p>{data.excerpt}</p>
    </div>
    </div>
    <div className="container mt-5">
    <Gallerys images={gallerys}/>
      {/* <Carousel showArrows={true} thumbWidth={ScreenWidth/6} showIndicators={false} infiniteLoop useKeyboardArrows swipeable  autoFocus>
          {
              gallerys.map((img,index:number)=> (<div key={`g${index}`}><img src={`https://admin.penguinasset.com/uploads/assetstore/${data.code!}/${img}`} alt={data.name} /></div>))   
          }
      </Carousel> */}
    </div>
    <div className='container'>
        <div className='row'>
            <div className='col-12 col-sm-12 col-12 text-end asset-view'>
            <NumberFormat value={data.viewed} displayType={'text'} thousandSeparator={true} />  Views <img src='../../icon/view.png' className='view'/>{(!loading)?<Favorite item={data}/>:<></>}<a className='cursor-pointer' onClick={()=>handleShareShow()}><img src='../../icon/open.png' className='open'/></a>
            </div>
            <div className='col-12 col-sm-12 col-12 '>
            <div className='w-100 sepetor my-2'></div>
            </div>
        </div>
        <div className="row mb-4 asset-detail mt-4">
          <div className="col-12 col-sm-12 col-md-12 col-lg-7">
            <div className='asset-name'>ชื่อโครงการ : {data.name}</div>
            <div className='asset-desc mb-2'>รายละเอียด : รหัสสินทรัพย์ {data.code}</div>
            <div className='asset-province mb-1'>จังหวัด : {data.provinces_name}   <label className='ms-5'>ทำเล : {data.locality} </label></div>
            <div className='asset-tags'>
                {
                    (tags)?
                    <ul className="asset-tags">
                        {
                           JSON.parse(tags).map((item:any,index:number)=>
                                <li key={index}>{item.value}</li>
                           )
                        }
                    </ul>
                    :
                    <></>
                }
            </div>
            <div className="price-block my-4">
              <span className="saleprice">ราคา <NumberFormat value={(data.sale_price&&data.sale_price>0)?data.sale_price:data.price} displayType={'text'} thousandSeparator={true} /> บาท</span>
              {
                  (data.sale_price&&data.sale_price>0)?
              <span className="oldprice ms-4"><NumberFormat value={data.price} displayType={'text'} thousandSeparator={true} /> บาท</span>
              :<></>
}
            </div>
            <div className='asset-attribute mt-3 mb-5'>
                <div className='row'>
                     {
                      (data.assettypes_name&&data.assettypes_name!=="")?
                      <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/type.png'/><label className='a-title'>ประเภทสินทรัพย์</label><label className='a-value'>{data.assettypes_name}</label></div>
                      :
                      <></>
                     }
                    {
                      (data.announcementtypes_name&&data.announcementtypes_name!=="")?
                      <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/post.png'/><label className='a-title'>ประเภทประกาศ</label><label className='a-value'>{data.announcementtypes_name}</label></div>
                      :
                      <></>
                    }
                    {
                      (data.land_area_price!==""||data.land_area!=="")?
                      <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/area.png'/><label className='a-title'>เนื้อที่ดิน</label><label className='a-value'>{(data.asset_type?.toString()==="3")?(data.land_area!==null)?data.land_area+' ตร.ม.':"- "+' ตร.ม.':data.land_area_price?.replace("0-0-","")+' ตร.ว.'}</label></div>
                      :
                      <></>
                    }
                    {
                      (data.usable_area&&data.usable_area!=="")?
                      <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/size.png'/><label className='a-title'>พื้นที่ใช้สอย</label><label className='a-value'>{(data.usable_area)?data.usable_area:"-"} ตร.ม </label></div>
                      :
                      <></>
                    }
                    {
                      (data.width&&data.width!=="")?
                      <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/face.png'/><label className='a-title'>หน้ากว้าง</label><label className='a-value'>{(data.width)?data.width:"-"} เมตร</label></div>
                      :
                      <></>
                    }
                    {
                      (data.transfer_fee&&data.transfer_fee!=="")?
                      <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/transfer.png'/><label className='a-title'>ค่าโอน</label><label className='a-value'>{(data.transfer_fee)?data.transfer_fee:"-"}</label></div>
                      :
                      <></>
                    }
                    {
                      (data.train&&data.train!=="")?
                      <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/train.png'/><label className='a-title'>รถไฟฟ้า</label><label className='a-value'>{(data.train)?data.train:"-"}</label></div>
                      :
                      <></>
                    }
                    {
                      (data.turn_towards&&data.turn_towards!=="")?
                        <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/direction.png'/><label className='a-title'>หันไปทาง</label><label className='a-value'>{(data.turn_towards)?data.turn_towards:"-"}</label></div>
                      :<></>
                    }
                    {
                      (data.number_of_floors&&data.number_of_floors!=="")?
                      <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/flow.png'/><label className='a-title'>จำนวนชั้น</label><label className='a-value'>{(data.number_of_floors)?data.number_of_floors:"-"}</label></div>
                      :<></>
                    }
                    {
                      (data.bedroom&&data.bedroom!=="")?
                      <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/badroom.png'/><label className='a-title'>ห้องนอน</label><label className='a-value'>{(data.bedroom)?data.bedroom:"-"}</label></div>
                      :<></>
                    }
                    {
                      (data.bathroom&&data.bathroom!=="")?
                      <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/toilet.png'/><label className='a-title'>ห้องน้ำ</label><label className='a-value'>{(data.bathroom)?data.bathroom:"-"}</label></div>
                      :<></>
                    }
                    {
                      (data.kitchen&&data.kitchen!=="")?
                      <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/kitchen.png'/><label className='a-title'>ห้องครัว</label><label className='a-value'>{(data.kitchen)?data.kitchen:"-"}</label></div>
                      :<></>
                    }
                    {
                      (data.car_park&&data.car_park!=="")?
                      <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/parking.png'/><label className='a-title'>จอดรถ</label><label className='a-value'>{(data.car_park)?data.car_park:"-"}</label></div>
                      :<></>
                    }
                    {
                      (data.furniture&&data.furniture!=="")?
                      <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/furniture.png'/><label className='a-title'>เฟอร์นิเจอร์</label><label className='a-value'>{(data.furniture)?data.furniture:"-"}</label></div>
                      :<></>
                    }
                    {
                      (data.air&&data.air!=="")?
                      <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/air.png'/><label className='a-title'>แอร์</label><label className='a-value'>{(data.air)?data.air:"-"}</label></div>
                      :<></>
                    }
                    {
                      (data.flooring_material&&data.flooring_material!=="")?
                      <div className='col-4 col-sm-3 col-md-2 attr'><img src='../../icon/attribute/floor.png'/><label className='a-title'>วัสดุปูพื้น</label><label className='a-value'>{(data.flooring_material)?data.flooring_material:"-"}</label></div>
                      :<></>
                    }
                    <div className='col-12 mb-4'>
                        <div className='facilities'>สิ่งอำนวยความสะดวก</div>
                    </div>
                    {
                    (facility)?
                    <>
                        {
                           facility.map((item:any,index:number)=>
                           <div className='col-4 col-sm-3 col-md-2 attr' key={`x${index}`}><img src={`https://admin.penguinasset.com${item.icon}`}/><label className='a-title'>{item.name}</label></div>
                           )
                        }
                    </>
                    :
                    <></>
                }
                   
                </div>
                
            </div>
            <div className='row mb-3'>
                <div className="col-12">{(data.desc)?parse(data.desc!):""}</div>
            </div>                
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-5 contact-pannel">
                <div className="w-100">
                {(data.map)?parse(data.map!):""}
                </div>
                <div className="w-100 p-relative button-contact">
                    <img src="../../penguin/penguin22.png" className="penguin-22"/>
                    <div className='asset-contact'>
                    ติดต่อฝ่ายขาย <a href={`tel:${data.contact_number}`}> <img src='../../icon/tel.png' />{data.contact_number}</a>
                    </div>
                </div>
                <div className="w-100 text-center">
                    <p>หรือต้องการให้เจ้าหน้าที่ติดต่อกลับ กรุณากรอกข้อมูล</p>
                </div>
                <div className="w-100">
                <form >
                    <div className="form-group mb-4">
                        <label htmlFor="name"><span className='text-danger'>*</span> ชื่อ-นามสกุล</label><small id="nameHelp" className="float-right form-text text-muted">(<span className='text-danger'>*</span> แสดงข้อมูลที่จำเป็นต้องใช้)</small>
                        <input type="text" onChange={setInputvalue} value={formInput.name} className="form-control penguinInput" name="name" id="name" aria-describedby="nameHelp" placeholder="ชื่อ-นามสกุล"/>
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor="phone"><span className='text-danger'>*</span> เบอร์โทรศัพท์</label>
                        <input type="text" onChange={setInputvalue} value={formInput.phone} className="form-control penguinInput" name="phone" id="phone" placeholder="เบอร์โทรศัพท์"/>
                        <div className="text-danger text-warning">{(phoneErr!=="")? phoneErr:""}</div>
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor="message"><span className='text-danger'>*</span> ข้อความ (สามารถใส่ได้ไม่เกิน 300 ตัวอักษร)</label>
                        <textarea maxLength={300} onChange={setInputvalue} className="form-control penguinInput" name="message" id="message" placeholder="ข้อความ" value={formInput.message}>{formInput.message}</textarea>
                    </div>
                    <div className="mb-4 text-left">
                        <input type="checkbox" className="form-check-input" name="consent" id="consent"  onChange={setConsentvalue} checked={(consent)?true:false}/>
                        <p className="form-check-label ms-2 policy-text d-inline" >ข้าพเจ้าตกลงและยินยอมที่จะให้ บริษัท เดอะ ไวท์สเปช จำกัด เก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุลคลของข้าพเจ้า  ตามวัตถุประสงค์
                          ที่ได้กำหนดไว้ในนโยบายและประกาศการคุ้มครองข้อมูลของบริษัท บริษัทจะดำเนินการเท่าที่จำเป็นเพื่อรักษาข้อมูลส่วนบุลคลของท่าน
                          ตามกฎหมายคุ้มครองข้อมูลส่วนบุลคลที่เกี่ยวข้อง <a href="/policy-notiecs">นโยบายการคุ้มครองข้อมูลส่วนบุลคล</a></p>
                    </div>
                    <div className='w-100 text-center'>
                    <button type="button" onClick={()=>submitForm()} className="btn btn-penguin text-white">ส่งข้อความ</button>
                    </div>
                </form>
                </div>
          </div>
        </div>
        
        <div className='row'>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 contact-pannel ps-0">
                <div className="w-100 p-relative button-contact">
                    <img src="../../penguin/penguin22.png" className="penguin-22"/>
                    <div className='asset-contact'>
                    สินทรัพย์ใกล้เคียง
                    </div>
                </div>
        </div>
            <div className='property-list mb-5'>
                <div className='row'>
                {/* {relates &&relates.filter((rl:any) => rl.code!== code&&rl.assettypes_name=== data.assettypes_name).map((item:any, index:number) => ( */}
                {relates &&relates.filter((rl:any) => rl.code!== code).map((item:any, index:number) => (
                 <div className="col-12 col-sm-6 col-md-3" key={`cc${index}`}><Item asset={item}/></div>    
             ))
            }
                </div>
            </div>
        </div>
    </div>
    {
      (sending)?
        <div className="overlay">
          <div className="custom-loader"></div>
        </div>
        :
        <></>
    }
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>ส่งข้อมูลเรียบร้อย</Modal.Title>
        </Modal.Header>
        <Modal.Body>ขอบคุณที่สนใจสินค้าของเรา ทางเราจะรีบติดต่อกลับหาท่านโดยเร็ว</Modal.Body>
        <Modal.Footer>
          <Button variant="primary px-4" onClick={handleClose}>
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={shareShow} onHide={handleShareClose} centered>
        <Modal.Header closeButton className="py-1">
          <Modal.Title>แชร์</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <FacebookShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name}`}
            quote={data.name}
            className="mx-1"
            onClick={()=>share("facebook")}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          
          <LineShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name}`}
            title={data.name}
            className="mx-1"
            onClick={()=>share("line")}
          >
            <LineIcon size={32} round />
          </LineShareButton>
          {/* <PinterestShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name}`}
            media  ={`https://penguinasset.com${data.image!}`}
            description  ={data.excerpt}
            className="mx-1"
          >
            <PinterestIcon size={32} round />
          </PinterestShareButton> */}
          <TwitterShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name}`}
            title={data.name}
            className="mx-1"
            onClick={()=>share("twitter")}
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          
          <EmailShareButton
            url={`https://penguinasset.com/asset/${data.code+"/"+data.name}`}
            subject={data.name}
            body="body"
            className="Demo__some-network__share-button"
          >
            <EmailIcon size={32} round onClick={()=>share("email")}/>
          </EmailShareButton>
      </Modal.Body>
      
      </Modal>
    <Footer/>
    </HelmetProvider>
    :
    <></>
        
  );
}

export default Asset;
