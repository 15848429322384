import React, { Component, useState, useEffect } from 'react';
import axios from "axios"
import Slider from "react-slick";
export default function Banner(props:any) {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:true,
      autoplay:true
    };
    const [banners, setBanners] = useState([]);
    const [loader, setLoader] = useState(false);
    const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);
    const getBanners = async () => {
      try {
        setLoader(true)
        const response = await axios.get(
          `https://admin.penguinasset.com/api/banners`,{params:{page:props.page}});
          setBanners(response.data);
        setError(null);
        setLoader(false)
      } catch (err:any) {
        setError(err.message);
        setLoader(false)
      } finally {
        setLoading(false);
        setLoader(false)
      }
    };
    
    useEffect(() => {
     if(banners.length === 0){
      getBanners()
     }
      
    }, []);
    return (
        <div className='container-fuild'>
      <Slider {...settings}>
        {
            (banners.length>0)?
            banners&&banners.map((item:any, index:number) => (
              <div key={`banner${index}`}>
                {
                  (item.url&&item.url!==null)?
                  <a href={`${item.url}`}><img src={`https://admin.penguinasset.com${item.banner_desktop}`} className='w-100'/></a>
                  :
                  <img src={`https://admin.penguinasset.com${item.banner_desktop}`} className='w-100'/>
                }
              </div>
              ))
             : 
             <>
             </>
            }
      </Slider>
      {
        (loader)?
          <div className="overlay">
            <div className="custom-loader"></div>
          </div>
          :
          <></>
      }
      </div>
      
    );
  }